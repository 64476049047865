import { image_url_prefix } from "./config.js";
import { housing_associations } from "./housing_associations.js";

const projects_start_end_dates = {
  C004: { start: "23-12-2021", end: "21-09-2023" },
  C005: { start: "08-01-2024", end: "03-07-2024" },
  C006: { start: "22-04-2022", end: "12-07-2023" },
  C007: { start: "02-02-2023", end: "02-04-2025" },
  C008: { start: "11-05-2022", end: "29-07-2024" },
  C010: { start: "12-12-2022", end: "02-05-2025" },
  C012: { start: "08-08-2022", end: "26-07-2024" },
  C014: { start: "30-10-2024", end: "16-12-2025" },
  C015: { start: "20-09-2023", end: "28-05-2025" },
  C017: { start: "27-09-2023", end: "02-07-2025" },
  C019: { start: "21-08-2023", end: "24-11-2025" },
  C023: { start: "30-10-2024", end: "01-09-2026" },
  C024: { start: "03-02-2025", end: "06-08-2026" },
  C028: { start: "25-11-2024", end: "06-08-2026" },
  C030: { start: "13-05-2024", end: "26-03-2026" },
  C031: { start: "01-04-2025", end: "01-02-2027" },
  C037: { start: "02-09-2024", end: "02-07-2026" },
  C038: { start: "09-06-2025", end: "25-01-2027" },
  C041: { start: "09-12-2024", end: "26-03-2027" },
  C053: { start: "07-04-2025", end: "13-11-2026" },
};

const projects_description_config = {
  gap: "gap-4",
  text_size: "text-base",
  text_color: "text-black",
  link_color: "text-castell",
};

const project_descriptions = {
  C001: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Llys Waters is a project that has been undertaken by Castell Group to demolish an old and dilapidated OAP Centre
        and develop the land into nine EPC A affordable housing units across two blocks for registered social landlords{" "}
        <a
          aria-label={`View Caredig's website`}
          href={housing_associations.caredig.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Caredig
        </a>
        .
      </div>
      <div>Jon Harvey, Head of Development at Caredig said: </div>
      <div className="italic">
        “We’ve built an extremely good working relationship with Castell Group – this is the first time we’ve worked
        with them and we’re already looking forward to working with them on future projects.”
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Llys Waters`}
          href={`${image_url_prefix}98-Case Study - Llys Waters.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C002: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Tŷ Cam is a project that has been undertaken by Castell Group to convert the majority of a derelict and
        dilapidated old Victorian building (Big Cam) from a public house into 10 EPC A affordable housing units for{" "}
        <a
          aria-label={`View Tai Tarian's website`}
          href={housing_associations.tai_tarian.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Tai Tarian
        </a>{" "}
        housing association, and a section of the ground floor is to be converted into an A3 commercial unit.
      </div>
      <div>
        The project has involved major structural work to bring the building to a safe and strong standard, and also
        included a new roof. We have achieved energy performance rating of A on this development, and found this site
        through a letter and have funded this project 100% through private investment.
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Tŷ Cam`}
          href={`${image_url_prefix}98-Case Study - Big Cam.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C003: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Cwrt y Nant is a project that has been undertaken by Castell Group to develop 10 EPC A affordable housing units
        for{" "}
        <a
          aria-label={`View Tai Tarian's website`}
          href={housing_associations.tai_tarian.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Tai Tarian
        </a>{" "}
        housing association. This project involved the demolition of a old derelict snooker club and full refurbishment
        of a derelict building next door.
      </div>
      <div>
        We have built 10 social housing EPCA high quality apartments including two that have been adapted for disabled
        users and we’ve also converted the existing building that was already there back into a high quality
        three-bedroom house for the open market.
      </div>
      <div>
        This project involved the construction of two blocks, onsite parking, solar panels, bike stores, and more. We
        funded this project through 100% private investment.
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Cwrt Y Nant`}
          href={`${image_url_prefix}98-Case Study - Cwrt Y Nant.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C004: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Trallwn Farm Close is a project carried out by Castell Group in Llansamlet, Swansea. The project involves the
        demolition of an old garage and the construction of 12 EPC A energy-rated affordable housing units for{" "}
        <a
          aria-label={`View Caredig's website`}
          href={housing_associations.caredig.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Caredig
        </a>
        . These units include both one and two bedrooms, with four designed specifically for disabled users.
      </div>
      <div>
        The project involved the construction of two semi-detached blocks built to lifetime home standards and has also
        achieved a Secure by Design Gold Standard Certificate. Additionally, this development features a Rain Garden
        that cultivates wildflowers to assist with drainage, hedgehog openings in the fence panels, and a bat box. We
        found this site through a letter sent to the vendor, and it has been entirely funded by private investment.
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Trallwn Farm Close`}
          href={`${image_url_prefix}98-Case Study - Trallwn Farm Close.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C005: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Cowbridge Road is a project that had been undertaken by Castell Group to develop 12 EPC A affordable housing
        units for housing association{" "}
        <a
          aria-label={`View Hafod's website`}
          href={housing_associations.hafod.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Hafod
        </a>{" "}
        .
      </div>
      <div>
        The project is located at 77 Cowbridge Road, Bridgend and comprises an existing house (no 77) and the rear
        gardens of numbers 77 and 75.
      </div>
      <div>
        This project involved the construction of two blocks, onsite parking, solar panels, bike stores, and more. We
        funded this project through 100% private investment.
      </div>
      <div>
        The rear gardens was used to construct one block containing ten of the flats, along with associated works such
        as onsite parking, and number 77 was converted into the remaining two flats.
      </div>
      <div>
        This project also involves solar panels, and two wheelchair accessible units. Check out the video below for more
        info.
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Cowbridge Road`}
          href={`${image_url_prefix}98-Case Study - Cowbridge Road.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C006: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Crown Inn is a project undertaken by Castell Group to demolish a public house (Crown Inn) and in its place
        develop four houses.
      </div>
      <div>
        These houses are in the form of two blocks of two semi-detached houses. This project involves onsite parking,
        solar panels, and more. We built these houses in partnership with{" "}
        <a
          aria-label={`View Linc Cymru's website`}
          href={housing_associations.linc.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Linc Cymru
        </a>
        .
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Crown Inn`}
          href={`${image_url_prefix}98-Case Study - Crown Inn.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C007: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Eagle House is a project that has been undertaken by Castell Group to develop 18 EPC A affordable housing units
        and 4 retail units for{" "}
        <a
          aria-label={`View Tai Tarian's website`}
          href={housing_associations.tai_tarian.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Tai Tarian
        </a>{" "}
        housing association.
      </div>
      <div>
        This project involves the construction of a single large 5 storey block, onsite parking, solar panels, bike
        stores, and more.
      </div>
    </div>
  ),
  C008: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Frederick Place is a project that has been undertaken by Castell Group to develop 15 EPC A affordable housing
        units for registered social landlord{" "}
        <a
          aria-label={`View Caredig's website`}
          href={housing_associations.caredig.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Caredig
        </a>
        .
      </div>
      <div>This project involves onsite parking, solar panels, bike stores, and more.</div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Frederick Place`}
          href={`${image_url_prefix}98-Case Study - Frederick Place.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C010: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Samlet Road is a project that has been undertaken by Castell Group to develop a 35 homes on behalf of{" "}
        <a
          aria-label={`View Caredig's website`}
          href={housing_associations.caredig.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Caredig
        </a>{" "}
        on a brownfield site adjacent to Aldi, Samlet Road, Swansea.
      </div>
      <div>
        The scheme will comprise of a mix of 1 and 2 bed apartments, and 2 and 3 bedroom houses with associated amenity
        space.
      </div>
    </div>
  ),
  C012: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Llys Victoria is a project that has been undertaken by Castell Group to deliver 18 apartments on behalf of{" "}
        <a
          aria-label={`View Newport City Homes' website`}
          href={housing_associations.newport_city_homes.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Newport City Homes
        </a>{" "}
        at the former Victoria pub, Nash Road, Newport.
      </div>
      <div>
        The pub which has run into a state of disrepair will be demolished and replaced with a new apartment block
        comprising of 12 x 1B2 apartments and 6 x 2B3P apartments.
      </div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Llys Victoria`}
          href={`${image_url_prefix}98-Case Study - Llys Victoria.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C014: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        MOAT Farm is a project that has been undertaken by Castell Group to develop 7 EPC A affordable housing units for
        a local housing association.
      </div>
      <div>The scheme comprises of 4 one bedroom apartments, and 4 two bedroom houses.</div>
      <div>
        We’ll be working with a local registered social landlord to deliver these affordable homes for those most in
        need.
      </div>
    </div>
  ),
  C015: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Fruit Warehouse is a project that has been undertaken by Castell Group to develop 14 EPC A affordable housing
        units for{" "}
        <a
          aria-label={"View Newydd's website"}
          href={housing_associations.newydd.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Newydd
        </a>{" "}
        .
      </div>
      <div>
        The scheme comprises of 14 one bedroom apartments, with Welsh Government grant support, the project is now
        underway.
      </div>
    </div>
  ),
  C017: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Rhondda Bowl is a project that has been undertaken by Castell Group to develop 20 EPC A affordable housing units
        for{" "}
        <a
          aria-label={`View Newydd's website`}
          href={housing_associations.newydd.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Newydd
        </a>{" "}
        .
      </div>
      <div>The scheme comprises of:</div>
      <ul className="list-disc list-inside ml-4">
        <li>12x 1 bed apartments</li>
        <li>3x 2 bedroom houses</li>
        <li>5x 3 bedroom houses</li>
      </ul>
      <div>With Welsh Government grant support, the project is now underway.</div>
    </div>
  ),
  C019: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Wind Street is a project that has been undertaken by Castell Group to redevelop an old Working Men’s club into
        38 apartments on behalf of{" "}
        <a
          aria-label={"View Pobl's website"}
          href={housing_associations.pobl.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Pobl
        </a>
        .
      </div>
      <div>
        The existing building will be demolished and and replaced with a new apartment block comprising of a mixture of
        1 and 2 bed apartments.
      </div>
    </div>
  ),
  C022: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Heol y Groes is a project that has been undertaken by Castell Group in Pencoed, South Wales to develop 24
        apartments for social housing in partnership with{" "}
        <a
          aria-label={`View Valleys 2 Coast's website`}
          href={housing_associations.valleys_2_coast.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Valleys 2 Coast
        </a>
        .
      </div>
      <div>The development includes two blocks of nine apartments and two blocks of three.</div>
      <div>
        Download the{" "}
        <a
          aria-label={`Download the case study for Heol y Groes`}
          href={`${image_url_prefix}98-Case Study - Heol y Groes.pdf`}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          CASE STUDY
        </a>
      </div>
    </div>
  ),
  C023: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        We have exchanged contracts with{" "}
        <a
          aria-label={`Linc Cymru's website`}
          href={housing_associations.linc.link}
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          Linc Cymru
        </a>
        , to provide 26 much needed affordable homes at the former New Forge, Oakdale, Caerphilly.
      </div>
      <div>
        Welsh Government grant support will enable these 26 new homes to be delivered. We look forward to meeting and
        working with the local community to ensure that this development has a positive impact on the local area, as we
        prepare to commence work on site.
      </div>
    </div>
  ),
  C024: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        A brand-new development is in the works for Carmarthenshire! The PAC (pre-application consultation) for 19 new
        and affordable, EPC A rated homes is now live.
      </div>
      <div>
        We’re planning to partner with a local registered social landlord to deliver much needed houses and apartments
        on our land in the Capel Hendre area of Carmarthenshire.
      </div>
    </div>
  ),
  C026: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Castell Group have exchanged contracts on a golden brick agreement with{" "}
        <a
          aria-label={`View Linc Cymru's website`}
          href={housing_associations.linc.link}
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Linc Cymru
        </a>{" "}
        to deliver 18 much needed affordable homes in Pontymister, Caerphilly.
      </div>
      <div>
        <a
          aria-label={`View Welsh Government's website`}
          href="https://www.gov.wales/"
          target="_blank"
          rel="noreferrer"
          className={`${projects_description_config.link_color} underline`}
        >
          Welsh Government
        </a>{" "}
        grant support has enabled the scheme to come to fruition.
      </div>
      <div>Works are now underway, and we look forward to continuing to deliver homes for those truly in need.</div>
    </div>
  ),
  C028: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Castell Group have submitted a planning application for a proposed residential development of 20 EPC A dwellings
        in Ffordd y Bedol, Coed y Cwm.
      </div>
      <div>
        The proposals include sustainable drainage, landscape planting, vehicle parking and associated infrastructure.
      </div>
      <div>
        We’ll be working with a local registered social landlord to deliver these affordable homes for those most in
        need.
      </div>
    </div>
  ),
  C030: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Works are underway for a residential development of 21, EPC A rated, one bedroom dwellings at the former YMCA
        Porth.
      </div>
      <div>
        The proposals include sustainable drainage, landscape planting, vehicle parking and associated infrastructure.
      </div>
      <div>
        We’ll be working with a local registered social landlord to deliver these affordable homes for those most in
        need.
      </div>
    </div>
  ),
  C031: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>
        Castell Group have submitted a planning application for a proposed residential development of 20 EPC A dwellings
        in Newport.
      </div>
      <div>
        The proposals include sustainable drainage, landscape planting, vehicle parking and associated infrastructure.
      </div>
      <div>
        We’ll be working with a local registered social landlord to deliver these affordable homes for those most in
        need.
      </div>
    </div>
  ),
  C037: () => (
    <div
      className={`flex flex-col w-full h-full ${projects_description_config.text_size} ${projects_description_config.text_color} ${projects_description_config.gap}`}
    >
      <div>Works are underway for a residential development of 20 EPC A dwellings in Bridgend.</div>
      <div>
        The proposals include sustainable drainage, landscape planting, vehicle parking and associated infrastructure.
      </div>
      <div>
        We’ll be working with{" "}
        <a
          aria-label={`Valleys to Coasts's website`}
          href={housing_associations.valleys_2_coast.link}
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          Valleys to Coast
        </a>{" "}
        to deliver these affordable homes for those most in need.
      </div>
    </div>
  ),
};

const projects_data = {
  C001: {
    id: "C001",
    project_name: "Heol Gerrig",
    given_name: "Llys Waters",
    address: {
      post_code: "SA5 9BP",
      number: "",
      street_name: "Heol Gerrig",
      district: "Treboeth",
      county: "Swansea",
      country: "Wales",
      longitude: "51.65411331841807",
      latitude: "-3.94748422152274",
      w3w: "wizard.fonts.rarely",
    },
    main_cgi_url: `${image_url_prefix}c001-cgi-2.JPG`,
    main_photo_url: "",
    main_video_url: "https://www.youtube.com/embed/nyNR7Jwq3hA",
    cgi_urls: [
      `${image_url_prefix}c001-cgi-1.JPG`,
      `${image_url_prefix}c001-cgi-2.JPG`,
      `${image_url_prefix}c001-cgi-3.JPG`,
      `${image_url_prefix}c001-cgi-4.JPG`,
    ],
    photo_urls: [],
    video_urls: ["https://www.youtube.com/embed/nyNR7Jwq3hA"],
    stage: "Completed",
    stage_id: "completed",
    units: 9,
    housing_association: "Caredig (Family)",
  },
  C002: {
    id: "C002",
    project_name: "Big Cam",
    given_name: "Ty Cam",
    address: {
      post_code: "SA11 1LS",
      number: "9",
      street_name: "Windsor Road",
      district: "Neath",
      county: "Neath Port Talbot",
      country: "Wales",
      longitude: "51.66170720362827",
      latitude: "-3.806337836563601",
      w3w: "copies.medium.silk",
    },
    main_cgi_url: `${image_url_prefix}c002-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "https://www.youtube.com/embed/kLOeNPxNTHU",
    cgi_urls: [`${image_url_prefix}c002-cgi-1.jpg`],
    photo_urls: [],
    video_urls: ["https://www.youtube.com/embed/kLOeNPxNTHU"],
    stage: "Completed",
    stage_id: "completed",
    units: 10,
    housing_association: "Tai Tarian",
  },
  C003: {
    id: "C003",
    project_name: "Caenant Terrace",
    given_name: "Cwrt y Nant",
    address: {
      post_code: "SA10 6UW",
      number: "",
      street_name: "Caenant Terrace",
      district: "Skewen",
      county: "Neath Port Talbot",
      country: "Wales",
      longitude: "51.66192573349723",
      latitude: "-3.8425667616983668",
      w3w: "remodels.cooked.dollars",
    },
    main_cgi_url: `${image_url_prefix}c003-cgi-3.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [
      `${image_url_prefix}c003-cgi-1.jpg`,
      `${image_url_prefix}c003-cgi-2.jpg`,
      `${image_url_prefix}c003-cgi-3.jpg`,
    ],
    photo_urls: [],
    video_urls: [],
    stage: "Completed",
    stage_id: "completed",
    units: 10,
    housing_association: "Tai Tarian",
  },
  C004: {
    id: "C004",
    project_name: "Trallwn Road",
    given_name: "Trallwn Farm Close",
    address: {
      post_code: "SA7 9XA",
      number: "",
      street_name: "Trallwn Road",
      district: "Llansamlet",
      county: "Swansea",
      country: "Wales",
      longitude: "51.65335027461627",
      latitude: "-3.8901317356038048",
      w3w: "moons.like.hype",
    },
    main_cgi_url: `${image_url_prefix}c004-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "https://www.youtube.com/embed/NhXoy_RIO_c",
    cgi_urls: [
      `${image_url_prefix}c004-cgi-1.jpg`,
      `${image_url_prefix}c004-cgi-2.jpg`,
      `${image_url_prefix}c004-cgi-3.jpg`,
    ],
    photo_urls: [],
    video_urls: ["https://www.youtube.com/embed/NhXoy_RIO_c"],
    stage: "Completed",
    stage_id: "completed",
    units: 12,
    housing_association: "Caredig (Family)",
  },
  C005: {
    id: "C005",
    project_name: "Cowbridge Road",
    given_name: "",
    address: {
      post_code: "CF31 3DH",
      number: "",
      street_name: "Cowbridge Road",
      district: "Bridgend",
      county: "Bridgend County Borough",
      country: "Wales",
      longitude: "51.501148797285886",
      latitude: "-3.5723333608782712",
      w3w: "pinch.indoor.fades",
    },
    main_cgi_url: `${image_url_prefix}c005-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "https://www.youtube.com/embed/iiEYirchXOM",
    cgi_urls: [`${image_url_prefix}c005-cgi-1.jpg`, `${image_url_prefix}c005-cgi-2.jpg`],
    photo_urls: [
      `${image_url_prefix}c005-img-1.png`,
      `${image_url_prefix}c005-img-2.png`,
      `${image_url_prefix}c005-img-3.png`,
    ],
    video_urls: ["https://www.youtube.com/embed/iiEYirchXOM"],
    stage: "Completed",
    stage_id: "completed",
    units: 12,
    housing_association: "Hafod",
  },
  C006: {
    id: "C006",
    project_name: "Crown Inn",
    given_name: "",
    address: {
      post_code: "CF33 6PL",
      number: "",
      street_name: "Pyle Road",
      district: "Pyle",
      county: "Bridgend County Borough",
      country: "Wales",
      longitude: "51.524291060009375",
      latitude: "-3.691858877248806",
      w3w: "verdict.unheated.coast",
    },
    main_cgi_url: `${image_url_prefix}c006-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c006-cgi-1.jpg`, `${image_url_prefix}c006-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Completed",
    stage_id: "completed",
    units: 4,
    housing_association: "Linc",
  },
  C007: {
    id: "C007",
    project_name: "Eagle House",
    given_name: "",
    address: {
      post_code: "SA13 1DF",
      number: "",
      street_name: "Oakwood Lane",
      district: "Port Talbot",
      county: "Port Talbot",
      country: "Wales",
      longitude: "51.592082551287966",
      latitude: "-3.7796245818832803",
      w3w: "lasted.cabin.tight",
    },
    main_cgi_url: `${image_url_prefix}c007-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [
      `${image_url_prefix}c007-cgi-1.jpg`,
      `${image_url_prefix}c007-cgi-2.jpg`,
      `${image_url_prefix}c007-cgi-3.jpg`,
    ],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 22,
    housing_association: "Tai Tarian",
  },
  C008: {
    id: "C008",
    project_name: "Frederick Place",
    given_name: "",
    address: {
      post_code: "SA7 9TT",
      number: "181",
      street_name: "Frederick Place",
      district: "Llansamlet",
      county: "Swansea",
      country: "Wales",
      longitude: "51.659136190906956",
      latitude: "-3.874977591086236",
      w3w: "sandbags.instance.sprains",
    },
    main_cgi_url: `${image_url_prefix}c008-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c008-cgi-1.jpg`, `${image_url_prefix}c008-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Completed",
    stage_id: "completed",
    units: 15,
    housing_association: "Caredig (Family)",
  },
  C010: {
    id: "C010",
    project_name: "Samlet Road",
    given_name: "",
    address: {
      post_code: "SA7 9AF",
      number: "100",
      street_name: "Samlet Road",
      district: "Llansamlet",
      county: "Swansea",
      country: "Wales",
      longitude: "51.660328527050545",
      latitude: "-3.9034541266987066",
      w3w: "month.patch.money",
    },
    main_cgi_url: `${image_url_prefix}c010-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "https://www.youtube.com/embed/L68oAiVBjaE",
    cgi_urls: [
      `${image_url_prefix}c010-cgi-1.jpg`,
      `${image_url_prefix}c010-cgi-2.jpg`,
      `${image_url_prefix}c010-cgi-3.jpg`,
      `${image_url_prefix}c010-cgi-4.jpg`,
      `${image_url_prefix}c010-cgi-5.jpg`,
      `${image_url_prefix}c010-cgi-6.jpg`,
      `${image_url_prefix}c010-cgi-7.jpg`,
    ],
    photo_urls: [],
    video_urls: ["https://www.youtube.com/embed/L68oAiVBjaE"],
    stage: "Construction",
    stage_id: "construction",
    units: 35,
    housing_association: "Caredig (Family)",
  },
  C012: {
    id: "C012",
    project_name: "The Victoria Inn",
    given_name: "Llys Victoria",
    address: {
      post_code: "NP19 4NG",
      number: "2",
      street_name: "Nash Road",
      district: "Newport",
      county: "Newport",
      country: "Wales",
      longitude: "51.58015173596194",
      latitude: "-2.9581019684888332",
      w3w: "monday.same.duke",
    },
    main_cgi_url: `${image_url_prefix}c012-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c012-cgi-1.jpg`, `${image_url_prefix}c012-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Completed",
    stage_id: "completed",
    units: 18,
    housing_association: "NCH",
  },
  C014: {
    id: "C014",
    project_name: "Moat Farm",
    given_name: "",
    address: {
      post_code: "CF71 7NQ",
      number: "",
      street_name: "",
      district: "Llysworny",
      county: "Vale of Glamorgan",
      country: "Wales",
      longitude: "51.458292143400946",
      latitude: "-3.4952386536876396",
      w3w: "park.blown.target",
    },
    main_cgi_url: `${image_url_prefix}c014-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c014-cgi-1.jpg`, `${image_url_prefix}c014-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Planning",
    stage_id: "planning",
    units: 7,
    housing_association: "Newydd",
  },
  C015: {
    id: "C015",
    project_name: "Fruit Warehouse",
    given_name: "",
    address: {
      post_code: "CF39 8EY",
      number: "",
      street_name: "Penrhiwfer Road",
      district: "Tonyrefail",
      county: "Rhondda Cynon Taf",
      country: "Wales",
      longitude: "51.586502796158676",
      latitude: "-3.435895166863035",
      w3w: "hooks.headline.estimates",
    },
    main_cgi_url: `${image_url_prefix}c015-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c015-cgi-1.jpg`, `${image_url_prefix}c015-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 14,
    housing_association: "Newydd",
  },
  C017: {
    id: "C017",
    project_name: "Rhondda Bowl",
    given_name: "",
    address: {
      post_code: "CF39 8EW",
      number: "",
      street_name: "Waunrhydd Road",
      district: "Tonyrefail",
      county: "Rhondda Cynon Taf",
      country: "Wales",
      longitude: "51.58379370659077",
      latitude: "-3.4325297291900063",
      w3w: "establish.harmonica.jigsaw",
    },
    main_cgi_url: `${image_url_prefix}c017-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [
      `${image_url_prefix}c017-cgi-1.jpg`,
      `${image_url_prefix}c017-cgi-2.jpg`,
      `${image_url_prefix}c017-cgi-3.jpg`,
    ],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 20,
    housing_association: "Newydd",
  },
  C019: {
    id: "C019",
    project_name: "Neath Workingmens Club",
    given_name: "Wind Street",
    address: {
      post_code: "SA11 3NA",
      number: "",
      street_name: "Wind Street",
      district: "Neath",
      county: "Neath",
      country: "Wales",
      longitude: "51.6643990470226",
      latitude: "-3.8025675912334465",
      w3w: "parade.losses.panels",
    },
    main_cgi_url: `${image_url_prefix}c019-cgi-1.jpeg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [
      `${image_url_prefix}c019-cgi-1.jpeg`,
      `${image_url_prefix}c019-cgi-2.jpg`,
      `${image_url_prefix}c019-cgi-3.jpg`,
    ],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 38,
    housing_association: "Pobl",
  },
  C022: {
    id: "C022",
    project_name: "Pencoed ROAB",
    given_name: "Heol y Groes",
    address: {
      post_code: "CF35 5PE",
      number: "",
      street_name: "Heol y Groes",
      district: "Pencoed",
      county: "Bridgend County Borough",
      country: "Wales",
      longitude: "51.52406798623039",
      latitude: "-3.4994551499908555",
      w3w: "callers.points.helpfully",
    },
    main_cgi_url: `${image_url_prefix}c022-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [
      `${image_url_prefix}c022-cgi-1.jpg`,
      `${image_url_prefix}c022-cgi-2.jpg`,
      `${image_url_prefix}c022-cgi-3.jpg`,
    ],
    photo_urls: [],
    video_urls: [],
    stage: "Completed",
    stage_id: "completed",
    units: 24,
    housing_association: "Valleys 2 Coast",
  },
  C023: {
    id: "C023",
    project_name: "Oakdale",
    given_name: "",
    address: {
      post_code: "NP12 0DG",
      number: "",
      street_name: "Oakdale Terrace",
      district: "Penmaen",
      county: "Caerphilly",
      country: "Wales",
      longitude: "51.68122602001567",
      latitude: "-3.169149030999679",
      w3w: "tearfully.positions.dumpy",
    },
    main_cgi_url: `${image_url_prefix}c023-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c023-cgi-1.jpg`, `${image_url_prefix}c023-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 26,
    housing_association: "Linc",
  },
  C024: {
    id: "C024",
    project_name: "Capel Hendre",
    given_name: "",
    address: {
      post_code: "SA18 3RJ",
      number: "",
      street_name: "Bryn Dreinog",
      district: "Capel Hendre",
      county: "Carmarthenshire",
      country: "Wales",
      longitude: "51.78298903793005",
      latitude: "-4.047516000570139",
      w3w: "memory.freely.terms",
    },
    main_cgi_url: `${image_url_prefix}c024-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c024-cgi-1.jpg`, `${image_url_prefix}c024-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Planning",
    stage_id: "planning",
    units: 19,
    housing_association: "Pobl",
  },
  C026: {
    id: "C026",
    project_name: "Pontymister Station",
    given_name: "",
    address: {
      post_code: "NP11 6JP",
      number: "",
      street_name: "Newport Road",
      district: "Risca",
      county: "Caerphilly",
      country: "Wales",
      longitude: "51.60168036903373",
      latitude: "-3.079338157990731",
      w3w: "allergy.chemistry.dorms",
    },
    main_cgi_url: `${image_url_prefix}c026-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c026-cgi-1.jpg`, `${image_url_prefix}c026-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 18,
    housing_association: "Linc",
  },
  C028: {
    id: "C028",
    project_name: "Glyncoch",
    given_name: "Ffordd y Bedol, Coed y Cwm ",
    address: {
      post_code: "CF37 3JA",
      number: "",
      street_name: "Ffordd y Bedol",
      district: "Pontypridd",
      county: "",
      country: "Wales",
      longitude: "51.62687344186377",
      latitude: "-3.332840326076376",
      w3w: "expiring.crumb.reference",
    },
    main_cgi_url: `${image_url_prefix}c028-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c028-cgi-1.jpg`, `${image_url_prefix}c028-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Planning",
    stage_id: "planning",
    units: 20,
    housing_association: "Hafod",
  },
  C030: {
    id: "C030",
    project_name: "YMCA Porth",
    given_name: "",
    address: {
      post_code: "CF39 9LS",
      number: "",
      street_name: "River Terrace",
      district: "Porth",
      county: "Rhondda Cynon Taf",
      country: "Wales",
      longitude: "51.61003081709177",
      latitude: "-3.402086370084718",
      w3w: "nobody.optimally.download",
    },
    main_cgi_url: `${image_url_prefix}c030-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c030-cgi-1.jpg`, `${image_url_prefix}c030-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 21,
    housing_association: "Newydd",
  },
  C031: {
    id: "C031",
    project_name: "Marryat Walk",
    given_name: "",
    address: {
      post_code: "NP20 3LJ",
      number: "",
      street_name: "Marryat Walk",
      district: "Gaer",
      county: "Newport",
      country: "Wales",
      longitude: "51.57116088161567",
      latitude: "-3.01962593760998",
      w3w: "robots.erase.pocket",
    },
    main_cgi_url: `${image_url_prefix}c031-cgi-2.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c031-cgi-1.jpg`, `${image_url_prefix}c031-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Planning",
    stage_id: "planning",
    units: 20,
    housing_association: "NCH",
  },
  C037: {
    id: "C037",
    project_name: "Bettws Social Club",
    given_name: "",
    address: {
      post_code: "CF32 8SY",
      number: "",
      street_name: "Heol Dewi Sant",
      district: "Bettws",
      county: "Bridgend",
      country: "Wales",
      longitude: "51.56985589655011",
      latitude: "-3.5902703124380655",
      w3w: "guises.bleak.outlawing",
    },
    main_cgi_url: `${image_url_prefix}c037-cgi-1.jpg`,
    main_photo_url: "",
    main_video_url: "",
    cgi_urls: [`${image_url_prefix}c037-cgi-1.jpg`, `${image_url_prefix}c037-cgi-2.jpg`],
    photo_urls: [],
    video_urls: [],
    stage: "Construction",
    stage_id: "construction",
    units: 20,
    housing_association: "Valleys 2 Coast",
  },
};

export { projects_data, project_descriptions, projects_start_end_dates };
