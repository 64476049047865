import { template_image_dark } from "./image-links.js";
import { image_url_prefix } from "./config.js";
import { insertTextbeforeUrlExtention } from "../general/text-formatting.js";
import { res_types } from "./image-resolutions.js";
import { b_roll_urls } from "./image-links.js";

const blog_data = {
  charity_of_the_year_2025: {
    id: `charity_of_the_year_2025`,
    no: 25,
    active: true,
    title: `Castell Group’s Charity of the Year 2025`,
    date: `2025-01-15`,
    overview: `We are pleased to announce our partnership with the Lighthouse Construction Industry Charity as our Charity of the Year for 2025...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0025-1.png`, res_types[2]),
      image_alt: `Charity of the Year 2025`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0025-1.png`, res_types[3]),
            image_alt: `Charity of the Year 2025`,
          },
        },
        {
          text_block: [
            {
              text: `We are pleased to announce our partnership with the `,
            },
            {
              text: `Lighthouse Construction Industry Charity`,
              italic: true,
            },
            {
              text: ` as our Charity of the Year for 2025. This collaboration highlights our commitment to promoting a healthier, safer, and more supportive construction community.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `About the Lighthouse Construction Industry Charity`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Lighthouse Charity is the only organisation in the UK and Ireland dedicated solely to the wellbeing of construction workers and their families. Their services include:`,
            },
          ],
        },
        {
          bullet_points: [
            {
              header: `24/7 Confidential Helpline:`,
              text: `Offering emotional, physical, and financial support.`,
            },
            {
              header: `Text HARDHAT Service:`,
              text: `Anonymous, text-based counselling.`,
            },
            {
              header: `Construction Industry Helpline App:`,
              text: `Providing resources, coping strategies, and signposting to over 3,000 accredited support organisations.`,
            },
            {
              header: `Wellbeing Academy:`,
              text: `Training in mental health, resilience, and workplace wellbeing.`,
            },
            {
              header: `Critical Incident Support:`,
              text: `Trauma response and specialist care for workplace crises.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The construction industry faces significant challenges, including mental health issues, high suicide rates, and occupational illnesses. With two construction workers taking their own lives every working day in the UK and Ireland, the need for proactive support is urgent.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Why This Partnership Matters`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At Castell, we recognise the pressures of the construction sector. Supporting the Lighthouse Charity aligns with our values and ensures workers have access to vital care, whether through counselling, financial guidance, or wellbeing resources.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Our Commitment for 2025`,
            },
          ],
        },
        {
          text_block: [{ text: `We will support the Lighthouse Charity through:` }],
        },
        {
          bullet_points: [
            {
              header: `Fundraising Events:`,
              text: `Including Lighthouse Days, charity challenges, and site activities.`,
            },
            {
              header: `Resource Sharing:`,
              text: `Distributing helpline cards, posters, and wellbeing materials across our sites.`,
            },
            {
              header: `Awareness Campaigns:`,
              text: `Promoting initiatives such as the “Help Inside the Hard Hat” campaign.`,
            },
            {
              header: `Team Participation:`,
              text: `Encouraging employee involvement in fundraising efforts.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Making a Difference`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `This partnership is about fostering a culture of care and support within the construction community. Together with the Lighthouse Charity, we aim to positively impact the lives of construction workers and their families.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `How You Can Help`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `To learn more about the Lighthouse Charity or to get involved, visit their website at `,
            },
            {
              link: `https://www.lighthouseclub.org`,
              text: `www.lighthouseclub.org`,
              aria_label: `Navigate to the Lighthouse Club's website`,
            },
            {
              text: `. Let’s work together to ensure no one in our industry faces challenges alone.`,
            },
          ],
        },
      ],
    ],
  },
  fast_growth_50_2024: {
    id: `fast_growth_50_2024`,
    no: 24,
    active: true,
    title: `Castell Group Crowned Fastest Growing Construction, Building & Property Services Firm in the UK`,
    date: `2025-01-15`,
    overview: `We are proud to announce that Castell Group has been named the Fastest Growing Construction, Building & Property Services Firm in the UK...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0024-1.png`, res_types[2]),
      image_alt: `Fast Growth 50 Winners`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0024-1.png`, res_types[4]),
            image_alt: `Fast Growth 50 Winners`,
          },
        },
        {
          text_block: [
            {
              text: `We are proud to announce that Castell Group has been named the `,
            },
            {
              text: `Fastest Growing Construction, Building & Property Services Firm in the UK`,
              italic: true,
            },
            {
              text: ` at this year’s UK Fast Growth Index Awards. This recognition marks a significant milestone in our journey, building on last year’s achievement of the same title for Wales. Being acknowledged on a UK-wide level is a testament to the dedication and hard work of our entire team.`,
            },
          ],
        },
      ],
      [
        {
          headings: [{ main: true, text: `Our Mission` }],
        },
        {
          text_block: [
            {
              text: `At Castell Group, we are committed to addressing the housing crisis in South Wales and beyond by delivering high-quality, energy-efficient social and disabled housing. This award highlights the importance of our work and strengthens our resolve to continue making a positive impact through innovative construction solutions.`,
            },
          ],
        },
      ],
      [
        {
          headings: [{ main: true, text: `The Awards Night` }],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0024-2.png`, res_types[4]),
            image_alt: `Fast Growth 50 Winners`,
          },
        },
        {
          text_block: [
            {
              text: `The awards ceremony, held at the Bloomsbury Big Top in London, brought together industry leaders to celebrate innovation and growth. Representing Castell Group on this memorable evening were Dorian Payne, Lyndsey Holmes, Mia Dixey, Dyfan Williams, Aled Hopkins, James Davies, Glenn Stroud, and Ethan Powell. Their presence showcased the drive and talent behind our success.`,
            },
          ],
        },
      ],
      [
        {
          headings: [{ main: true, text: `Acknowledgements` }],
        },
        {
          text_block: [
            {
              text: `We extend our sincere gratitude to our entire team, whose commitment has been key to achieving this recognition. We also thank our supply chain partners, clients, and supporters for trusting and working with us throughout this journey.`,
            },
          ],
        },
      ],
      [
        {
          headings: [{ main: true, text: `About the UK Fast Growth Index Awards` }],
        },
        {
          text_block: [
            {
              text: `These awards celebrate businesses demonstrating outstanding growth and innovation across industries. Winning this title reflects Castell Group’s standing as a leader in the construction and property services sector.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `This recognition inspires us to keep growing, innovating, and delivering quality housing projects across the UK. We look forward to building on this success and creating a brighter future together.`,
            },
          ],
        },
      ],
    ],
  },
  energy_efficient_social_housing: {
    id: `energy_efficient_social_housing`,
    no: 23,
    active: true,
    title: `Energy Efficient Social Housing: A Sustainable Future`,
    date: `2024-08-02`,
    overview: `In today’s world, sustainability is more than a buzzword; it’s a necessity. As the effects of climate change become increasingly apparent, the demand for energy-efficient...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0023-1.png`, res_types[2]),
      image_alt: `Mortgage Lane, Finance in the Fast Lane`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0023-1.png`, res_types[3]),
            image_alt: `Mortgage Lane, Finance in the Fast Lane`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Introduction",
            },
          ],
        },
        {
          text_block: [
            {
              text: "In today’s world, sustainability is more than a buzzword; it’s a necessity. As the effects of climate change become increasingly apparent, the demand for energy-efficient housing has surged. This is especially true in the social housing sector, where there is a pressing need to provide affordable, high-quality homes that are also environmentally friendly. Castell Construction is at the forefront of this movement, producing top-tier Energy Performance Certificate (EPC) A-rated homes for social housing providers. These homes not only meet the stringent requirements of modern energy efficiency standards but also offer significant benefits to both tenants and housing associations (HAs). Moreover, mortgage companies are now offering discounts to property owners with properties rated A-C, making energy efficiency an attractive proposition for investors. Expert mortgage brokers like ",
            },
            {
              link: "https://mortgagelane.com/",
              text: "Mortgage Lane",
              aria_label: "Navigate to Mortgage Lane's website",
            },
            {
              text: " play a crucial role in this landscape, providing market-leading mortgage products with discounts for energy-efficient properties and specialising in social housing buy-to-lets.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "The Importance of EPC Ratings",
            },
            {
              main: false,
              text: "What is an EPC Rating?",
            },
          ],
        },
        {
          text_block: [
            {
              text: "An Energy Performance Certificate (EPC) provides an assessment of a property’s energy efficiency. The rating ranges from A (most efficient) to G (least efficient). An EPC rating is determined based on factors such as insulation, heating systems, and energy consumption. A higher rating indicates better energy efficiency, leading to lower energy bills and a smaller carbon footprint.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Why EPC A-C Ratings are Preferred by Housing Associations",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Housing associations are increasingly prioritising EPC ratings A-C for several reasons:",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Cost Savings:",
              text: "Homes with higher EPC ratings are more energy-efficient, leading to significant cost savings on energy bills. This is particularly important for social housing tenants, who often have limited financial resources.",
            },
            {
              header: "Sustainability Goals:",
              text: "Many housing associations have committed to reducing their carbon footprints as part of broader sustainability goals. By ensuring their properties are energy efficient, they can contribute to environmental conservation efforts.",
            },
            {
              header: "Government Regulations and Incentives:",
              text: "Governments around the world are introducing stricter regulations and offering incentives for energy-efficient housing. Properties with higher EPC ratings may qualify for grants, subsidies, or tax breaks.",
            },
            {
              header: "Tenant Well-being:",
              text: "Energy-efficient homes provide a more comfortable living environment, reducing issues such as dampness and improving overall health and well-being.",
            },
          ],
        },
      ],
      [
        {
          headings: [{ main: true, text: "Castell Construction: Leading the Way in Energy Efficient Social Housing" }],
        },
        {
          text_block: [
            {
              text: "Castell Construction is a pioneer in the field of energy-efficient social housing. Their commitment to producing high-quality EPC A-rated homes is transforming the social housing landscape.",
            },
          ],
        },
        {
          headings: [{ main: false, text: "High-Quality EPC A Homes" }],
        },
        {
          text_block: [
            {
              text: "Castell Construction’s EPC A-rated homes are designed and built with the highest standards of energy efficiency in mind. Key features include:",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Superior Insulation:",
              text: "High-performance insulation materials ensure minimal heat loss, maintaining comfortable indoor temperatures year-round.",
            },
            {
              header: "Advanced Heating Systems:",
              text: "Energy-efficient heating systems, such as heat pumps and solar thermal panels, provide sustainable and cost-effective heating solutions.",
            },
            {
              header: "Renewable Energy Sources:",
              text: "Integration of renewable energy sources, such as solar panels, reduces reliance on non-renewable energy and lowers carbon emissions.",
            },
            {
              header: "Smart Home Technology:",
              text: "Incorporation of smart home technology allows residents to monitor and control energy usage, further enhancing efficiency.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Benefits for Social Housing Providers and Tenants",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Reduced Operational Costs:",
              text: "Energy-efficient homes require less maintenance and have lower operational costs, benefiting both housing providers and tenants.",
            },
            {
              header: "Enhanced Comfort and Health:",
              text: "Improved indoor air quality and consistent temperatures contribute to better health and well-being for residents.",
            },
            {
              header: "Increased Property Value:",
              text: "High EPC ratings can enhance property values, making them more attractive to investors and lenders.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Mortgage Discounts for Energy Efficient Properties",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Mortgage companies are increasingly recognising the value of energy-efficient homes and offering discounts to property owners with EPC ratings A-C. These discounts can take various forms, such as lower interest rates or reduced fees.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Why Mortgage Companies Prefer Energy Efficient Properties",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Lower Risk:",
              text: "Energy-efficient homes are often better maintained and have lower running costs, reducing the risk of default on mortgage payments.",
            },
            {
              header: "Sustainability Commitments:",
              text: "Many financial institutions have sustainability goals and are incentivised to support environmentally friendly investments.",
            },
            {
              header: "Market Demand:",
              text: "There is growing demand for energy-efficient homes, making them a more attractive investment for lenders.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Specialist Mortgage Broker for Social Housing Buy-to-Lets",
            },
          ],
        },
        {
          text_block: [
            {
              link: "https://mortgagelane.com/",
              text: "Mortgage Lane",
              aria_label: "Navigate to Mortgage Lane's website",
            },
            {
              text: " is a specialist mortgage broker with extensive experience in securing financing for social housing buy-to-lets. Their expertise in this niche market makes them an invaluable partner for investors and housing providers.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Market-Leading Mortgage Products",
            },
          ],
        },
        {
          text_block: [
            {
              link: "https://mortgagelane.com/",
              text: "Mortgage Lane",
              aria_label: "Navigate to Mortgage Lane's website",
            },
            {
              text: " offers a range of market-leading mortgage products tailored to energy-efficient properties. These products often include discounts and favourable terms for homes with EPC ratings A-C.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "How Mortgage Lane Assists with Energy Efficient Mortgages",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Expert Advice:",
              text: "Mortgage Lane’s team of experts provides personalised advice, helping clients navigate the complexities of energy-efficient mortgages.",
            },
            {
              header: "Access to Exclusive Deals:",
              text: "Through their extensive network of lenders, Mortgage Lane can secure exclusive deals and discounts for energy-efficient properties.",
            },
            {
              header: "Streamlined Process:",
              text: "Mortgage Lane simplifies the mortgage application process, ensuring a smooth and efficient experience for clients.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Benefits for Social Housing Providers",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Tailored Solutions:",
              text: "Mortgage Lane understands the unique needs of social housing providers and offers tailored mortgage solutions to meet those needs.",
            },
            {
              header: "Enhanced Financing Options:",
              text: "Access to favourable mortgage terms can improve the financial viability of social housing projects.",
            },
            {
              header: "Support for Sustainability Goals:",
              text: "By securing financing for energy-efficient homes, Mortgage Lane helps social housing providers achieve their sustainability objectives.",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Energy-efficient social housing is not just a trend; it’s a critical component of a sustainable future. Castell Construction is leading the way by producing high-quality EPC A-rated homes that offer numerous benefits to both housing providers and tenants. These homes reduce energy costs, enhance comfort, and contribute to environmental conservation efforts.",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Mortgage companies are increasingly offering discounts for properties with high EPC ratings, recognising the value and lower risk associated with energy-efficient homes. Expert mortgage brokers like Mortgage Lane play a crucial role in this ecosystem, providing market-leading mortgage products with discounts for energy-efficient properties and specialising in social housing buy-to-lets.",
            },
          ],
        },
        {
          text_block: [
            {
              text: "As the world moves towards a more sustainable future, the collaboration between innovative construction companies like Castell Construction and expert mortgage brokers like Mortgage Lane will be essential in driving the growth of energy-efficient social housing. Together, they are making a significant impact, ensuring that affordable, high-quality, and sustainable homes are available to those who need them most.",
            },
          ],
        },
      ],
    ],
  },
  affordable_housing_units_approved: {
    id: `affordable_housing_units_approved`,
    no: 22,
    active: true,
    title: `New Affordable Housing Units Approved!`,
    date: `2024-08-02`,
    overview: `New Affordable Housing Units Approved! We are thrilled to announce that 26 affordable housing units at the Former New Forge Public House in Oakdale were...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c023-cgi-2.jpg`, res_types[2]),
      image_alt: `Oakdale CGI`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c023-cgi-2.jpg`, res_types[3]),
            image_alt: `Oakdale CGI`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "New Affordable Housing Units Approved!",
            },
          ],
        },
        {
          text_block: [
            {
              text: "We are thrilled to announce that 26 affordable housing units at the Former New Forge Public House in Oakdale were approved by the planning committee, pending SAB approval. This is a significant step forward in our mission to provide 300 quality, affordable housing Per annum. We hope to be on-site with ",
            },
            {
              link: "https://www.linc-cymru.co.uk/",
              text: "Linc Cymru",
              aria_label: "Navigate to Linc Cymru's website",
            },
            {
              text: " in just a few weeks!",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Shoutout to the Castell Team",
            },
          ],
        },
        {
          text_block: [
            {
              text: "A big congratulations to the Land and Development team at Castell Group for their hard work and dedication. Your efforts have made this achievement possible, and we are proud of the progress we’ve made together. ",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Linc Cymru",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Linc Cymru is a Housing Association and Care provider. At Linc, they believe in creating the right environment for people to flourish. The homes they build and the natural environments that support them contribute to improving people’s quality of life, and as a care provider they work with Local Authorities and the NHS to address the needs of our ageing population. they are a socially responsible business focused on building a prosperous and healthier Wales.",
            },
          ],
        },
      ],
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0022-1.jpg`, res_types[2]),
            image_alt: `Oakdale Site Plan`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Seeking New Land Opportunities",
            },
          ],
        },
        {
          text_block: [
            {
              text: "We are always on the lookout for new land opportunities to continue our mission. Our criteria for potential sites are as follows:",
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Brownfield or Greenfield Sites:",
              text: "We are interested in both types of sites for our development projects.",
            },
            {
              header: "Preferably Inside the Settlement Boundary:",
              text: "While we prefer sites within the settlement boundary, we will also consider allocated sites that meet our needs.",
            },
            {
              header: "Between Half an Acre and 3 Acres:",
              text: "Ideal site sizes for our projects range from half an acre to three acres.",
            },
            {
              header: "Sustainable Areas Within an Hour and a Half from Swansea:",
              text: "We aim to develop in sustainable locations that are within an hour and a half’s travel from Swansea.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Let us know",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Get in touch If you if have any suitable sites or opportunities that meet our criteria, we would love to hear from you. Please get in touch – ",
            },
            {
              link: "mailto:hello@castellgroup.co.uk",
              text: "hello@castellgroup.co.uk",
              aria_label: "Email us at hello@castellgroup.co.uk",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Our mission",
            },
          ],
        },
        {
          text_block: [
            {
              text: "We are committed to delivering a minimum of 500 affordable, social, and disabled-accessible homes annually for those truly in need. This initiative will be executed in partnership with registered social landlords (RSLs) across South Wales. Our ongoing efforts have consistently brought us close to this target, and we remain determined to surpass it each year.",
            },
          ],
        },
      ],
    ],
  },
  dorian_outstanding_achiever_award: {
    id: `dorian_outstanding_achiever_award`,
    no: 21,
    active: true,
    title: `Dorian Payne Receives Outstanding Achiever Award with Constructing Excellence in Wales`,
    date: `2024-06-26`,
    overview: `We are thrilled to announce that Dorian Payne has been honoured with the Outstanding Achiever award at the prestigious Constructing Excellence in Wales awards held...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0021-1.jpg`, res_types[2]),
      image_alt: `Dorian Payne Receives Outstanding Achiever Award with Constructing Excellence in Wales`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0021-1.jpg`, res_types[2]),
            image_alt: `Dorian Payne Receives Outstanding Achiever Award with Constructing Excellence in Wales`,
          },
        },
        {
          text_block: [
            {
              text: "We are thrilled to announce that Dorian Payne has been honoured with the Outstanding Achiever award at the prestigious Constructing Excellence in Wales awards held last Friday. This accolade is a testament to Dorian’s exceptional contributions to the construction industry in Wales.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Recognising Excellence in Construction",
            },
          ],
        },
        {
          text_block: [
            {
              text: "The Constructing Excellence in Wales awards celebrate the best in the construction industry, recognising individuals, teams, and projects that exemplify excellence, innovation, and dedication. The Outstanding Achiever award is one of the most esteemed categories, spotlighting those who have made a significant impact on the industry.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Dorian Payne: A Leader in Construction",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Dorian Payne has long been a leading figure in the Welsh construction sector. His innovative approach, commitment to quality, and dedication to sustainable practices have set new standards in the industry. Under his leadership, numerous projects have not only met but exceeded expectations, contributing to the advancement of construction practices in Wales. Check out some of Castell Group’s projects ",
            },
            {
              link: "/projects",
              text: "here",
              aria_label: "Navigate to Castell Group's projects page",
            },
            {
              text: ".",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "Celebrating with the Team",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Although Dorian was unable to attend the awards ceremony, his team was present to celebrate this unexpected and joyous success. The team’s presence at the event underscored the collective effort and shared vision that drives their work. They accepted the award on Dorian’s behalf, marking a proud moment for everyone involved.",
            },
          ],
        },
        {
          text_block: [
            {
              text: "Dorian’s absence at the ceremony does not diminish the significance of this achievement. In fact, it highlights the strength of the team he has built—capable, dedicated professionals who carry forward his vision and values in every project they undertake.",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: "A Future of Continued Excellence",
            },
          ],
        },
        {
          text_block: [
            {
              text: "This award is not just a recognition of past achievements but also a motivation for future endeavours. Dorian Payne and his team remain committed to pushing the boundaries of what is possible in construction, continuously striving for excellence and innovation.",
            },
          ],
        },
        {
          text_block: [
            {
              text: "We extend our heartfelt congratulations to Dorian Payne and the entire team for this well-deserved honour. Their hard work, passion, and dedication continue to inspire and set a benchmark for the industry.",
            },
          ],
        },
        {
          text_block: [
            {
              text: "For more information about Constructing Excellence in Wales and the awards, visit their website.",
            },
          ],
        },
      ],
    ],
  },
  eagle_excellent_css_score: {
    id: `eagle_excellent_css_score`,
    no: 20,
    active: true,
    title: `Eagle House Development Receives Excellent CSS Score!`,
    date: `2024-05-29`,
    overview: `We’ve got some exciting news to share with you today – our Eagle House development for Tai Tarian has just hit a home run with...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c007-cgi-1.jpg`, res_types[1]),
      image_alt: `Eagle House Development Receives Excellent CSS Score!`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c007-cgi-1.jpg`, res_types[2]),
            image_alt: `Eagle House CGI 1`,
          },
        },
        {
          text_block: [
            {
              text: `We’ve got some exciting news to share with you today – our Eagle House development for `,
            },
            {
              link: "https://www.taitarian.co.uk/",
              text: `Tai Tarian`,
              aria_label: "Navigate to Tai Tarian's website",
            },
            {
              text: `, has just hit a home run with a CSS score of 41/45, securing its spot as an ‘excellent’ scheme. We couldn’t be more proud of this achievement.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Eagle House: A Project Overview`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `So, what exactly is Eagle House all about? This is a project brought to life by Castell Group to provide 20 EPC A affordable housing units and 4 retail spaces for Tai Tarian. Picture a towering five-story block that’s set to become the new heartbeat of the neighbourhood, complete with ample parking, solar panels to harness the power of the sun, and bike stores to encourage eco-friendly transportation. It’s not just a building – it’s a vision of a brighter, more sustainable future for all who call it home.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Celebrating Excellence: The CSS Score Breakdown`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Now, let’s dive into the juicy details – that CSS score of 41/45 that Eagle House has proudly flaunted. What does it all mean? Well, for starters, it’s a testament to the meticulous planning, unwavering dedication, and top-notch execution that has gone into making Eagle House the success story that it is. The Considerate Constructors Scheme doesn’t hand out scores like these for anyone – no, they’re reserved for projects that truly stand out from the crowd.`,
            },
          ],
        },
      ],
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c007-cgi-2.jpg`, res_types[2]),
            image_alt: `Eagle House CGI 2`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Looking to the Future: What’s Next for Eagle House?`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `With a CSS score of 41/45 in the bag, the future is looking bright for Eagle House. This project is more than just bricks and mortar – it’s a symbol of what can be achieved when people come together with a shared vision of creating something truly remarkable. As construction continues to progress and the finishing touches are put in place, we can’t wait to see Eagle House come to life in all its glory, standing tall as a beacon of excellence for generations to come.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Toast to Excellence at Eagle House`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `So, there you have it – the story of Eagle House, and the incredible journey that has brought us to this moment of celebration. We couldn’t be more thrilled to share this news with you all, and we hope that it inspires you to reach for the stars in your own endeavours, whatever they may be. Here’s to excellence, hard work, and the power of teamwork – may they continue to guide us on our path to success, just like they have at Eagle House. Cheers to a job well done! Read more about Eagle House `,
            },
            {
              link: `/projects/C007`,
              text: `here.`,
              aria_label: "Navigate to the Eagle House project page",
            },
          ],
        },
      ],
    ],
  },
  social_housing_initiative: {
    id: `social_housing_initiative`,
    no: 19,
    active: true,
    title: `Building Futures: A New Social Housing Initiative in Rhondda Cynon Taf`,
    date: `2024-05-22`,
    overview: `We have some exciting news to share. We have officially exchanged on a crucial social housing initiative in Rhondda Cynon Taf, and we couldn’t be...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0019-1.png`, res_types[1]),
      image_alt: `RCT Project Site Plan`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0019-1.png`, res_types[3]),
            image_alt: `RCT Project Site Plan`,
          },
        },
        {
          text_block: [
            {
              text: `We have some exciting news to share. We have officially exchanged on a crucial social housing initiative in Rhondda Cynon Taf, and we couldn’t be more thrilled to get started on this project. Teaming up with a local housing association, we are on a mission to address the urgent need for affordable and social housing in our community. With 17 units of housing in the works, we are dedicated to making a positive impact and supporting the mission of providing over 500 homes annually. Let’s dive into the details and spread some joy!`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Power of Partnership`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As they say, teamwork makes the dream work! We believe that collaboration is key to success, and by working together, we can achieve great things. Our shared goal of providing much-needed housing for those in our community who need it most is what drives us forward. Through open communication, mutual respect, and a shared vision, we are confident that we can make a real difference in the lives of our neighbours.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Addressing the Need in Rhondda Cynon Taf`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The need for social and affordable housing in Rhondda Cynon Taf is undeniable. Every day, families are struggling to find safe, secure, and affordable places to call home. By taking on this initiative, we are stepping up to the plate and doing our part to address this pressing issue. We believe that everyone deserves a place to live, no matter their circumstances. With 17 units of housing in the pipeline, we are making a tangible difference in the lives of those in need. It’s not just about building structures; it’s about building hope and a better future for our community.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Making a Positive Change`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Change is never easy, but it is necessary for growth and progress. By taking on this social housing initiative, we are committed to making a positive change in our community. We believe that everyone deserves a safe and affordable place to call home, and we are determined to make that a reality for as many people as possible. Through our dedication, hard work, and unwavering commitment, we are paving the way for a brighter future for all. Join us as we embark on this journey towards positive change and a better tomorrow for Rhondda Cynon Taf.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Planning Stages`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Ah, the planning stages – where dreams meet reality and hard work begins. As our social housing initiative progresses through the planning stages, we are faced with challenges and opportunities alike. From securing necessary approvals to fine-tuning the details of the development, there is much work to be done. But we are up for the challenge! With a dedicated team of professionals by our side, we are confident that we can navigate the planning process smoothly and efficiently. Stay tuned for updates as we make progress and move one step closer to bringing this project to life.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Sharing More Details`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As the saying goes, the devil is in the details. From the design of the housing units to the amenities and features that will be included, there is so much to look forward to. Our goal is not just to build houses; it’s to create homes where people can thrive and flourish. Each detail is carefully considered and crafted with the needs of our future residents in mind. We can’t wait to share more about this project with you as it unfolds, so stay tuned for updates and sneak peeks along the way.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Mission of Hope`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Hope is a powerful thing. It can light up even the darkest of days and inspire us to keep pushing forward. Our mission of providing over 500 homes annually is driven by a deep sense of hope and optimism. We believe that by coming together as a community and working towards a common goal, we can make a real difference in the lives of those in need. Through our dedication, hard work, and unwavering commitment, we are spreading hope and positivity in Rhondda Cynon Taf. Join us in our mission of hope, and together, we can build a better future for all.`,
            },
          ],
        },
      ],

      [
        {
          headings: [
            {
              main: true,
              text: `Spreading Joy in Rhondda Cynon Taf`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In a world filled with uncertainty and challenges, it’s important to spread joy wherever we can. And what better way to spread joy than by providing much-needed housing for those in need? Our social housing initiative is not just about building structures; it’s about building hope, happiness, and a sense of belonging. By coming together as a community and supporting one another, we can create a ripple effect of positivity that touches the lives of many. So let’s spread some joy, build some dreams, and make a difference one home at a time.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Embracing Diversity and Inclusion`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Diversity and inclusion are at the core of everything we do. We believe that everyone deserves a place to call home, regardless of their background, circumstances, or identity. Through our social housing initiative, we are committed to embracing diversity and inclusion in all aspects of the project. From ensuring accessibility for all to creating spaces that are welcoming and inclusive, we are dedicated to building a community where everyone feels valued and respected. By embracing diversity and inclusion, we are not just building houses; we are building a sense of belonging and unity that transcends differences.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Celebrating Progress`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Progress is a beautiful thing. It reminds us how far we’ve come and how much more we have yet to achieve. As our social housing initiative moves forward, we are celebrating every step of the journey. From exchanging on the project to securing approvals and moving towards construction, each milestone brings us closer to our goal of providing over 500 homes annually. We are proud of the progress we have made so far, but we know that the best is yet to come. Join us in celebrating progress and looking forward to a bright future filled with hope, happiness, and endless possibilities. You can check out our live sites `,
            },
            {
              link: `/projects`,
              text: `here.`,
              aria_label: "Navigate to Castell Group's projects page",
            },
          ],
        },
      ],
    ],
  },
  castell_shortlisted_3_awards_2024: {
    id: `castell_shortlisted_3_awards_2024`,
    no: 18,
    active: true,
    title: `Castell Group Shortlisted for 3 Prestigious Awards!`,
    date: `2024-05-16`,
    overview: `Exciting News: shortlisted for 3 awards We are thrilled to share some incredible news that has us bursting with excitement. Castell Group has been shortlisted...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0018-1.png`, res_types[1]),
      image_alt: `Wales 2024 Awards Shortlist`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0018-1.png`, res_types[3]),
            image_alt: `Wales 2024 Awards Shortlist`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Exciting News: shortlisted for 3 awards`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We are thrilled to share some incredible news that has us bursting with excitement. Castell Group has been shortlisted for not just one, not two, but three prestigious awards! This recognition is a testament to the hard work and dedication of our entire team, and we could not be more grateful for this honour. From Building Project of the Year to Residential Project of the Year, our projects have been recognised for their excellence and innovation. And to top it all off, our very own MD, Dorian Payne, has been shortlisted for the Future Leader Wales award.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Testimony to Team Dedication`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Castell Group is a tight-knit team of passionate individuals who are committed to delivering top-notch projects and exceeding expectations. Each member of our team plays a crucial role in the success of our company, and it is their dedication and hard work that has led to this momentous occasion. From our skilled construction workers to our meticulous contract managers, everyone has contributed to the success of our projects and our MD’s well-deserved recognition. We couldn’t be prouder of the team we have assembled and the incredible work they have produced.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Looking Forward to the Awards Ceremony`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we eagerly await the awards ceremonies, we are filled with anticipation and excitement. This event is not just a celebration of our achievements, but also an opportunity to connect with other industry professionals and celebrate the successes of our peers. It will be a night to remember, filled with inspiring stories and well-deserved recognition. We are counting down the days until we can don our finest attire and attend this prestigious event, where we hope to walk away with some well-deserved awards.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Thrilled for the Opportunity`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Being shortlisted for these awards is a tremendous honour and a validation of the hard work and dedication we put into every project. Find out all categories we are shortlisted for below:`,
            },
          ],
        },
        {
          headings: [
            {
              main: false,
              text: `Institute of Directors`,
              link: `https://www.iod.com/events/wales_director_of_the_year_awards2377868835/`,
              aria_label: "Navigate to the Institute of Directors' website",
            },
          ],
        },
        {
          bullet_points: [
            {
              text: `Small / Medium Business (Up to £15mil)`,
            },
          ],
        },
        {
          headings: [
            {
              main: false,
              text: `Constructing Excellence in Wales`,
              link: `https://www.cewales.org.uk/`,
              aria_label: "Navigate to Constructing Excellence in Wales' website",
            },
          ],
        },
        {
          bullet_points: [
            {
              text: `Building Project of the Year`,
            },
            {
              text: `Residential Project of the Year`,
            },
            {
              text: `G4C Future Leader (Dorian Payne)`,
            },
          ],
        },
        {
          headings: [
            {
              main: false,
              text: `Wales Business Awards`,
              link: `https://cw-seswm.com/events/awards-2024-enter/`,
              aria_label: "Navigate to the Wales Business Awards' website",
            },
          ],
        },
        {
          bullet_points: [
            {
              text: `Young Entrepreneur of the Year (Dorian Payne)`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Celebrating Innovation and Creativity`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Innovation and creativity are at the core of everything we do at Castell Group. From conceptualising cutting-edge designs to implementing sustainable building practices, we are constantly pushing the boundaries of what is possible in the construction industry. Our projects are a testament to our innovative spirit and our commitment to pushing the envelope in terms of design and construction. Being shortlisted for these awards is a validation of our innovative approach and a recognition of the creativity that drives us to do better, be better, and achieve more.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Bright Future Ahead`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we look to the future, we are filled with hope and excitement for what lies ahead. The recognition we have received through these awards is just the beginning of what we hope to achieve as a company. We are constantly striving to improve and innovate, to exceed expectations and set new standards within the industry. The future is bright for the Castell Group, and we are excited to see where our creativity and dedication will take us next.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Congratulations to All Shortlisted`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We would like to extend our heartfelt congratulations to all the other finalists who have been shortlisted for these prestigious awards. It is an honour to be recognised alongside such talented and dedicated individuals and companies, and we look forward to celebrating with you all at the awards ceremony. The construction industry is filled with remarkable individuals who are pushing the boundaries of what is possible, and we are proud to be a part of this inspiring community.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Gratitude and Appreciation`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Finally, we would like to express our sincere gratitude to everyone who has been a part of these incredible projects and who has contributed to the success of Castell Group. From our clients and partners to our hardworking team members, we could not have achieved this recognition without your support and dedication. Thank you for believing in us, for pushing us to be better, and for being a part of this incredible journey. We are immensely grateful for your continued support and look forward to celebrating our successes together.`,
            },
          ],
        },
      ],
    ],
  },
  exchange_on_pembroke_initiative: {
    id: `exchange_on_pembroke_initiative`,
    no: 17,
    active: true,
    title: `We’ve exchanged on an important social housing initiative in Pembroke!`,
    date: `2024-05-15`,
    overview: `We’re thrilled to announce that we have recently signed on an important social housing initiative in Pembroke. By partnering with a local housing association, we...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0017-1.png`, res_types[1]),
      image_alt: `Pembroke Project Site Plan`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0017-1.png`, res_types[3]),
            image_alt: `Pembroke Project Site Plan`,
          },
        },
        {
          text_block: [
            {
              text: `We’re thrilled to announce that we have recently signed on an important social housing initiative in Pembroke. By partnering with a local housing association, we are determined to address the pressing need for housing in our community. This project, pending planning and SAB approval, is set to deliver a total of 38 units of social and affordable housing. We couldn’t be more excited to embark on this meaningful journey and make a positive impact on the lives of those in need in our area.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A New Beginning`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `This collaboration marks a new beginning for us as we step into a new area. As a team, we are committed to working towards creating a more inclusive and supportive community for all. By focusing on providing affordable housing options, we hope to offer a sense of stability and security to those who are currently struggling to find suitable accommodation. This initiative is more than just bricks and mortar – it’s about building a brighter future for everyone involved.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Addressing a Critical Need`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The need for social and affordable housing in Pembroke is undeniable. With rising housing costs and a shortage of suitable accommodation options, many individuals and families are left in precarious living situations. By prioritising the development of social housing units, we are taking proactive steps towards alleviating this crisis. Our goal is to create a safe and welcoming environment where everyone has access to decent and affordable housing. It’s about more than just providing shelter – it’s about restoring dignity and hope to those who need it most.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Building a Better Future`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We firmly believe that everyone deserves a place to call home. By investing in social housing initiatives like this one, we are investing in the future of our community. Providing stable housing options not only improves the quality of life for individuals and families, but it also strengthens the fabric of our society. We are dedicated to creating a more inclusive and sustainable community where everyone has the opportunity to thrive and succeed. This project is just the beginning of our journey towards a brighter and more equitable future for all.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Breaking Down Barriers`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `One of the key objectives of this social housing initiative is to break down the barriers that prevent individuals and families from accessing safe and affordable housing. By offering a mix of social and affordable units, we are catering to a diverse range of needs within our community. We are committed to creating a more inclusive and welcoming environment where everyone is valued and supported. Through this project, we hope to set a positive example for others in the community and inspire further progress towards a more equitable society.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Celebrating Progress`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Every step forward in this social housing initiative is a cause for celebration. From the initial planning stages to securing approvals and breaking ground on construction, each milestone brings us one step closer to seeing our vision of a more inclusive and supportive community. We are grateful for the opportunity to be a part of this important project and are excited to see the positive impact it will have on the lives of those in need. By celebrating our progress and achievements, we are building momentum and inspiration for the work that lies ahead.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Engaging the Community`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Community engagement is essential to the success of this social housing initiative. We are committed to involving community members in the planning and development process, ensuring that their voices are heard, and their needs are met. By fostering open communication and collaboration, we are creating a sense of ownership and pride in the project. We believe that by working together, we can achieve greater outcomes and create a stronger sense of community cohesion. Engaging the community in this initiative is not just a strategy – it’s a core value that drives our commitment to creating a more inclusive and supportive environment for all.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Overcoming Challenges`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `No worthwhile endeavour comes without its challenges, and this social housing initiative is no exception. From navigating regulatory hurdles to securing funding and resources, we have faced obstacles along the way. However, we are determined to overcome these challenges and stay focused on our goal of providing safe and affordable housing for those in need. By approaching each challenge with creativity and perseverance, we are confident that we can find solutions and move forward with our mission. We are resilient in the face of adversity and are committed to making this initiative a success, no matter the obstacles we may encounter.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Brighter Tomorrow`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In conclusion, we are incredibly excited about the potential impact of this social housing initiative in Pembroke. By partnering with a local housing association and focusing on the urgent need for housing in our community, we are taking meaningful steps towards creating a more inclusive and supportive environment for all. Through collaboration, dedication, and a shared vision for the future, we are confident that we can make a real difference in the lives of those in need. This project is a testament to our commitment to building a brighter tomorrow for everyone. We look forward to the positive changes this project will bring about, and can’t wait to share more along the way.`,
            },
          ],
        },
      ],
    ],
  },
  new_housing_tonyrefail: {
    id: `new_housing_tonyrefail`,
    no: 16,
    active: true,
    title: `Updates on New Housing Developments in Tonyrefail`,
    date: `2024-04-25`,
    overview: `Hey there, fellow housing enthusiasts! We’ve got some exciting news to share about two key housing developments in Tonyrefail that are set to provide dozens...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c017-cgi-1.jpg`, res_types[2]),
      image_alt: `Rhondda Bowl CGI`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c017-cgi-1.jpg`, res_types[3]),
            image_alt: `Rhondda Bowl CGI`,
          },
        },
        {
          text_block: [
            {
              text: `Hey there, fellow housing enthusiasts! We’ve got some exciting news to share about two key housing developments in Tonyrefail that are set to provide dozens of new homes in the area. Not only will these properties offer affordable housing options, but they will also be A-rated for energy performance thanks to the use of a non-fossil fuel heating system. So sit back, relax, and let’s dive into all the details of these upcoming projects.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Beginnings of Something Great`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Picture this – a former bowling alley and an old fruit warehouse in Tonyrefail being transformed into vibrant communities with a total of 34 new homes. That’s the vision behind the major housing developments at the former Rhondda Bowl and Fruit and Vegetable Wholesale warehouse, both of which are expected to be completed in 2025 by Castell Group.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c015-cgi-2.jpg`, res_types[3]),
            image_alt: `Fruit Warehouse CGI`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Affordable and Energy-Efficient Housing`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Newydd Housing Association, the driving force behind these projects, has made affordability, energy efficiency, and decarbonisation a top priority. The former fruit warehouse development will feature 14 one-bedroom apartments for rent, while the former Rhondda Bowl will offer a mix of one-bedroom apartments and two and three-bedroom houses for rent.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Bright Future for Tonyrefail`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `With planning consent secured in 2023, these developments are being designed to meet the latest environmental standards and will boast A-rated energy performance certificates thanks to their non-fossil fuel heating systems. The aim is to have these homes completed by the summer of 2025, providing much-needed housing options for residents in Tonyrefail.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Ready to Make a Difference`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Residents interested in renting these properties will need to register with HomefinderRCT to apply, ensuring fair and transparent allocation of these high-quality homes. The Rhondda Bowl, which gained fame as a filming location for the Netflix series Sex Education, will soon be home to a 100% social housing scheme, offering 20 houses and flats to those in need.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Partnerships for Progress`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Louise Davies, director of public health, protection, and community services at Rhondda Cynon Taf Council, highlighted the collaborative efforts between the council and Newydd Housing Association in designing these schemes to meet local housing needs. With an investment of around £5m via the Social Housing Grant, these developments are set to make a positive impact on the community.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Commitment to Excellence`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Jason Wroe, chief executive of Newydd, expressed excitement about the opportunity to build more affordable homes in Tonyrefail. These properties will not only contribute to meeting net zero targets but will also provide residents with safe, warm, and affordable housing options. The focus is on delivering high-quality homes that align with the Welsh Housing Quality Standard.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0016-1.jpg`, res_types[3]),
            image_alt: `Rhondda Bowl Pictured in December 2023`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Building for the Future`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Dorian Payne, managing director at Castell Group, emphasised the company’s dedication to making a meaningful impact in communities like Tonyrefail. Through partnerships with Newydd and ongoing projects at Waunrhydd Road and Bryn Rhedyn, Castell Group is committed to delivering high-quality homes for those in need. With a focus on community engagement and local investment, these developments hold the promise of a brighter future for all.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Creating Positive Change`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `By working with local subcontractors and suppliers, establishing training and apprenticeship programs, and investing in the local area, Newydd and Castell Group are committed to creating a positive impact across the community. The goal is to reduce housing waiting lists in the area and provide much-needed EPC A-rated homes for social rent.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Shared Vision for Success`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The collaborative efforts of Newydd, Castell Group, and various partners are set to bring to life 34 new affordable homes in Tonyrefail, making a significant contribution to the community. With groundworks already underway and progress being made on both developments, the future is looking bright for residents in need of quality housing options.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Looking Ahead`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we eagerly anticipate the completion of these developments in 2025, we can’t help but look forward to the positive impact they will have on the community. Stay tuned for more updates as these projects continue to flourish and provide much-needed housing solutions for the residents of Tonyrefail.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `View all of Castell Group’s projects `,
            },
            {
              link: `/projects`,
              text: `here.`,
              aria_label: "Navigate to Castell Group's projects page",
            },
          ],
        },
      ],
    ],
  },
  nexgen_heating_system: {
    id: `nexgen_heating_system`,
    no: 15,
    active: true,
    title: `Introducing NexGen Electric Wallpaper Heating Systems`,
    date: `2024-04-11`,
    overview: `Welcome to our blog post highlighting the exciting implementation of the NexGen Electric Wallpaper heating system at the Victoria Inn project in Newport by Castell...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0015-2.jpg`, res_types[2]),
      image_alt: `NexGen Installation`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0015-2.jpg`, res_types[2]),
            image_alt: `NexGen Electric Wallpaper Heating Systems`,
          },
        },
        {
          text_block: [
            {
              text: `Welcome to our blog post highlighting the exciting implementation of the NexGen Electric Wallpaper heating system at the Victoria Inn project in Newport by Castell Group. This innovative technology is set to revolutionise the way we think about heating in homes, prioritising safety, efficiency, and comfort. Join us as we delve into the features of this state-of-the-art system and how it will benefit residents of the Victoria Inn project.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Benefits of NexGen Electric Wallpaper Heating System`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The NexGen Electric Wallpaper system offers a range of benefits that set it apart from traditional heating solutions. By combining Far Infrared radiant heating with conductive heating, this system provides a balanced and effective way to keep homes warm and cozy. Its low voltage and low surface temperature design ensure that there is zero risk of any human injury, making it a safe choice for families. Additionally, the zoned system allows for room-by-room control, optimising thermal efficiency and ensuring a consistent temperature throughout the space.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `“We are thrilled to introduce the NexGen Electric Wallpaper system at our Victoria Inn project. This cutting-edge technology not only offers a modern heating solution but also prioritises safety and energy efficiency,” said a Castell representative. This commitment to innovation and excellence is what sets Castell Group apart as a leader in property development, dedicated to providing high-quality and sustainable living spaces for residents.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Features of NexGen Wallpaper`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The NexGen Electric Wallpaper system features a range of impressive attributes that make it an ideal choice for modern homes. With its sleek design and discreet installation, this system seamlessly integrates into any living space, providing a subtle yet effective heating solution. The system’s ability to combine Far Infrared radiant heating with conductive heating ensures that homes are heated evenly and efficiently, creating a comfortable living environment for residents.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In addition to its innovative heating capabilities, the NexGen Electric Wallpaper system also offers the added benefit of zoned control. This feature allows residents to adjust the temperature in each room individually, ensuring that energy is not wasted on heating unused spaces. By optimising thermal efficiency and providing precise temperature control, this system helps to reduce energy consumption and lower utility costs for homeowners.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0015-1.jpg`, res_types[2]),
            image_alt: `NextGen Wallpaper`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Implementation at Victoria Inn Project`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group is proud to announce the implementation of the NexGen Electric Wallpaper heating system at their Victoria Inn project in Newport. This cutting-edge technology will provide residents with a safe, efficient, and comfortable heating solution, enhancing their overall living experience. In addition to the NexGen Electric Wallpaper system, Castell Group will also provide the option for more traditional heating sources for future tenants. However, the company is excited to embrace this new, innovative technology and offer residents a modern and sustainable living experience.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `“We are proud to offer residents at the Victoria Inn project the latest in heating technology with the NexGen Electric Wallpaper system. This state-of-the-art system aligns perfectly with our commitment to creating high-quality and sustainable living spaces for all our residents.” By incorporating this innovative technology into our projects, Castell Group continues to provide residents with modern and comfortable homes that meet their unique needs.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Conclusion`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In conclusion, the implementation of the NexGen Electric Wallpaper heating system at the Victoria Inn project in Newport marks a significant step forward for Castell Group in our commitment to innovation. This state-of-the-art technology offers residents a safe, efficient, and comfortable heating solution, enhancing their overall living experience. With features such as zoned control and precise temperature regulation, the NexGen Electric Wallpaper system is set to revolutionise the way we think about heating in homes, creating a modern and sustainable living environment for all residents.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `View all of Castell Group’s projects `,
            },
            {
              link: `/projects`,
              text: `here.`,
              aria_label: "Navigate to Castell Group's projects page",
            },
          ],
        },
      ],
    ],
  },
  more_housing_merthyr: {
    id: `more_housing_merthyr`,
    no: 14,
    active: true,
    title: `Bringing More Social Housing to Merthyr Tydfil`,
    date: `2024-03-20`,
    overview: `In the bustling town of Merthyr Tydfil, Castell Group is making waves with an exciting new project in collaboration with a local housing association. The...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0014-1.png`, res_types[2]),
      image_alt: `Merthyr Project Site Plan`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0014-1.png`, res_types[2]),
            image_alt: `Merthyr Project Site Plan`,
          },
        },
        {
          text_block: [
            {
              text: `In the bustling town of Merthyr Tydfil, Castell Group is making waves with an exciting new project in collaboration with a local housing association. The exchange on this significant social housing development marks the beginning of a journey towards addressing the critical need for housing in our community. With 28 social and affordable housing units on the horizon, we are committed to making a positive impact and contributing to the mission of delivering over 500 homes per year. Join us as we dive into the details of this exciting venture and share our vision for the future.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Power of Collaboration`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At Castell Group, we believe in the power of collaboration to drive positive change in our communities. Our partnership with a local housing association for this social housing project in Merthyr Tydfil is a testament to our commitment to working together towards a common goal. By joining forces with like-minded individuals and organisations, we can leverage our collective strengths and resources to make a meaningful impact on the lives of those in need of affordable housing. Together, we can create a stronger, more resilient community where everyone has access to safe and secure housing.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Navigating the Planning Process`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we move forward with this exciting social housing project in Merthyr Tydfil, we must navigate the intricate planning process to bring our vision to life. From submitting the application to securing planning and SAB approval, there are many steps along the way that require careful consideration and attention to detail. We are committed to following all necessary procedures and working closely with our partners and stakeholders to ensure a smooth and successful development process. With the support of NP Linnells, RDP Law, and C2J Architects & Planners, we are confident that we can overcome any challenges that may arise and bring this important project to fruition.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Embracing Sustainability`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In addition to addressing the critical need for housing in our community, our social housing project in Merthyr Tydfil is also a testament to our commitment to sustainability and environmental responsibility. We understand the importance of building homes that are not only affordable and accessible but also energy-efficient and environmentally friendly. By incorporating sustainable design principles and practices into our development, we are taking steps towards reducing our carbon footprint and creating a more sustainable future for generations to come. With all our homes achieving an EPC A rating, we are dedicated to building homes that are as good for the planet as they are for the people who live in them.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Community Initiatives`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we embark on this exciting social housing project in Merthyr Tydfil, we are also committed to engaging with the local community and seeking input and feedback from residents and stakeholders. We believe in the power of community engagement to inform our decision-making process and ensure that our development meets the needs and priorities of those who will ultimately benefit from it. By listening to the voices of the community and incorporating their feedback into our planning and design process, we can create a more inclusive and responsive development that truly serves the needs of the people it is intended to help. Together, we can build a stronger, more connected community where everyone has a place to call home.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Stay Tuned`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As our social housing project in Merthyr Tydfil progresses through the planning process, we will be revealing further details and updates on our website and social media channels. From design concepts and construction timelines to community events and volunteer opportunities, we will keep our supporters and stakeholders informed every step of the way. Be sure to stay tuned for the latest news and announcements as we continue to move forward with this exciting project. Together, we can make a difference and build a brighter future for all.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `View all of Castell Group’s projects `,
            },
            {
              link: `/projects`,
              text: `here.`,
              aria_label: "Navigate to Castell Group's projects page",
            },
          ],
        },
      ],
    ],
  },
  castell_pobl_break_ground_neath_workingmans: {
    id: `castell_pobl_break_ground_neath_workingmans`,
    no: 13,
    active: true,
    title: `Castell Group and Pobl Break Ground on 36 Apartment Development at Former Neath Workingmen’s Club`,
    date: `2024-02-14`,
    overview: `Neath, Wales – Castell Group is thrilled to announce that they we officially broken ground on a 36 apartment development at the former Neath Workingmen’s...`,
    image: {
      image_url: insertTextbeforeUrlExtention(b_roll_urls[3], res_types[3]),
      image_alt: `Castell Group and Pobl Break Ground`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(b_roll_urls[3], res_types[4]),
            image_alt: `Castell Group and Pobl Break Ground on 36 Apartment Development at Former Neath Workingmen’s Club`,
          },
        },
        {
          text_block: [
            {
              text: `Neath, Wales – Castell Group is thrilled to announce that they we officially broken ground on a 36 apartment development at the former Neath Workingmen’s Club site. The development is being carried out for local housing association, `,
            },
            {
              link: `https://www.poblgroup.co.uk/`,
              text: `Pobl`,
              aria_label: "Navigate to Pobl's website",
            },
            {
              text: `, in collaboration with Neath Port Talbot council, with the aim of providing social and affordable housing in the area. The groundbreaking event was attended by local council members, representatives from Pobl, and key members of the Castell Group team.`,
            },
          ],
        },
        {
          text_block: [
            {
              italic: true,
              text: `“We are proud to have completed all the piling work, with 119 piles now in the ground,” said Matthew Harbut, contracts manager at Castell Group. “The next phase of works will involve preparing the foundations for the new apartments.”`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Dorian Payne, Managing Director at Castell Group, shared, “Our target is to have the development completed and ready for handover by summer 2025. This project is an important step in revitalising Neath town centre, and will provide 36 EPC A rated social and affordable housing units for the community.”`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Claire Trishtam, Director of Development West at Pobl Group, said `,
            },
            {
              italic: true,
              text: `“We are committed to continued investment in Neath Port Talbot with the help of NPT politicians and officers and with the assistance of Welsh Government funding. These homes will provide much needed accommodation in Neath and help with the regeneration plans for the area. They will be highly sustainable reducing running costs for the residents and be a great addition to the area. We are also delighted to be working with Castell on their first project for Pobl.”`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Pobl has received additional funding through the Homes as Power Stations initiative, with an extra £300,000 allocated for this development. The funds will be used for the installation of innovative battery storage systems and photovoltaic panels which will help reduce electricity costs for tenants across all apartments.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group are excited to be a part of this transformative project and look forward to delivering high-quality housing solutions for the local community. To view other affordable housing projects we have underway, head over to our `,
            },
            {
              link: `/projects`,
              text: `projects`,
              aria_label: "Navigate to Castell Group's projects page",
            },
            {
              text: `.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Watch a short video from the day `,
            },
            {
              link: `https://youtu.be/c3mznXSbVeA?si=dCAxqnwH4qO_5Yw8`,
              text: `here`,
              aria_label: "Navigate to the video on YouTube",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
    ],
  },
  faster_than_getting_legal_permissions: {
    id: `faster_than_getting_legal_permissions`,
    no: 12,
    active: true,
    title: `Planning Data Revealed: Building Faster Than Getting Legal Permissions`,
    date: `2024-01-30`,
    overview: `Understanding the Planning Process When it comes to developing residential properties, one of the key factors that can significantly impact the project timeline is the...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0012-1.jpeg`, res_types[2]),
      image_alt: `Wales Planning Timescales`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0012-1.jpeg`, res_types[3]),
            image_alt: `Wales Planning Timescales`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Understanding the Planning Process`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `When it comes to developing residential properties, one of the key factors that can significantly impact the project timeline is the process of securing the relevant planning permission. It’s an essential step that can sometimes prolong the overall development period. Here at Castell Group, we have delved into our data to analyse the average timescales it takes us to obtain legal permission for social and affordable residential developments. We’ve also compared our data across various local authorities and are eager to hear from other developers and planning consultants about their experiences with the timescales. In this blog post, we’ll be sharing our insights and inviting others to join the conversation.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Read this `,
            },
            {
              link: `https://www.planningaidwales.org.uk/wp-content/uploads/2018/09/A-public-guide-to-landuse-planning-system-in-Wales-_Public_Handbook.pdf`,
              text: `handbook`,
              aria_label: "Navigate to the Planning Aid Wales handbook",
            },
            {
              text: ` for further information on the planning system in Wales.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Importance of Efficient Timelines`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As property developers, having a comprehensive understanding of the planning process and the timescales associated with it is essential for strategically managing development projects. The ability to accurately predict the time required to secure permissions can greatly influence the overall project plans and scheduling. By gaining insights into the average timescales for different stages of the process, we can better plan construction activities, resource allocation, and project timelines.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Castell Group’s Data Revealed`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our land and development team at Castell Group has gathered data on the average timescales it takes to secure permissions for social and affordable residential developments. Across various local authorities, we have analysed our planning applications for sites within the settlement boundary, with the exception of one rural site. The data excludes SAB and other requisite consents like S278/38 and`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Exploring Wales Planning Timescales`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In our analysis, we’ve specifically looked at timescales within the context of Wales. Pre-application stages typically take approximately 3 months, followed by a month for review and amendments. The process of submitting a Planning Application Consultation (PAC) and receiving the PAC report averages at 1-2 weeks, respectively. When it comes to the full planning application, the average duration stands at 6 months, with an additional 1-2 months for the planning committee stage. The negotiation and agreement of the S106 agreement typically require 1-2 months, and the judicial review period takes around 6 weeks. Pre-commencement conditions are usually addressed within 3-4 months, ultimately resulting in an average of 19.5 months for the time taken to start on site.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `It’s important to note that our build programmes average within 18 months, meaning that we are building faster than it takes to obtain the necessary legal permissions. With this data in mind, we are keen to understand the experiences of other developers and planning consultants in regard to their planning timescales.`,
            },
          ],
        },
      ],
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0012-2.jpg`, res_types[2]),
            image_alt: `Rhondda Bowl Site Plan`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Understanding the Planning Application Process`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Understanding the Planning Application Process The planning application process involves several critical stages, each with its own set of requirements and timelines. From the initial pre-application discussions to the submission of the full application and the subsequent planning committee review, developers navigate through a series of procedural steps. Understanding the intricacies of each stage, including the necessary documentation, consultations, and potential amendments, is crucial for expediting the process.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Streamlining the Process`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we examine the average timescales for obtaining permissions, it’s evident that there are opportunities to streamline the process for a faster start on site. This could involve a proactive approach to pre-application discussions and thorough preparation of the planning application to mitigate potential delays. Engaging with local authorities and stakeholders early in the process can contribute to a more efficient approval timeline, ultimately accelerating the commencement of construction activities.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Leveraging Positive Pre-Applications for Efficient Planning`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The significance of positive pre-applications cannot be understated when it comes to navigating the planning process. Our data reflects that all 16 of our own applications, except for one rural exception site, were within the settlement boundary and had positive pre-applications. Leveraging positive pre-apps can serve as a strategic advantage, providing clarity on the expectations of local authorities and enabling a more efficient progression to the full application stage.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Inviting Insights from Other Developers and Planning Consultants`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we continue to delve into our data and seek to optimise the planning process for efficiency, we are eager to hear from other developers and consultants about their experiences with planning timescales. By fostering an open dialogue and sharing insights, we can collectively identify best practices, potential challenges, and innovative approaches to navigating this process. Understanding the nuances of planning timelines across different local authorities and development contexts can contribute to a more comprehensive perspective on the industry landscape.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Balancing Efficiency with Regulatory Compliance`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `While there is a clear emphasis on expediting the planning process for efficient land development, it’s essential to maintain a balanced approach that upholds regulatory compliance and fosters collaboration with local authorities. Striking a balance between efficiency and regulatory adherence is fundamental for sustainable development practices. By cultivating strong relationships with local authorities, developers can navigate the planning process with transparency and accountability, ultimately fostering a collaborative environment for successful project execution.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In an industry where precision and timing are paramount, the use of data-driven insights can revolutionise planning and development practices. Embracing data-driven innovation is not only a strategic advantage but also a catalyst for advancing the collective impact of sustainable, affordable, and socially responsible residential development.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Take a look at our `,
            },
            {
              link: `/projects`,
              text: `completed projects`,
              aria_label: "Navigate to Castell Group's projects page",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
    ],
  },
  castell_charity_of_the_year_2024: {
    id: `castell_charity_of_the_year_2024`,
    no: 11,
    active: true,
    title: `Castell Group Announces Charity of the Year 2024`,
    date: `2024-01-19`,
    overview: `Castell Group are excited to announce our partnership with Matthews House, a charity dedicated to providing hospitality and hope to the homeless and vulnerable in...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0011-1.png`, res_types[2]),
      image_alt: `Charity of the Year 2024`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0011-1.png`, res_types[3]),
            image_alt: `Charity of the Year 2024`,
          },
        },
        {
          text_block: [
            {
              text: `Castell Group are excited to announce our partnership with Matthews House, a charity dedicated to providing hospitality and hope to the homeless and vulnerable in Swansea. The idea for Matthews House was born out of a desire to revive a Grade 2 listed building and transform it into a place of refuge and support for those in need. The charity has since launched several initiatives, including Matt’s Cafe, The Homeless Period Swansea, and Matt’s Community Choir, all aimed at addressing the issues of homelessness and period poverty in the community.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Story of Matthews House`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The journey of Matthews House began in 2016 when The Hill Church purchased the St Matthew’s building, which had been closed for nearly two years. With the help of donated funds and countless volunteer hours, the building was transformed into a house of hospitality and hope. The first initiative, Matt’s Cafe, was launched in 2017, intercepting edible food destined for disposal and turning it into nutritious meals for those in need. The concept quickly gained support from the community, inspiring the launch of The Homeless Period Swansea, an initiative to provide essential sanitary products to homeless women. The charity further expanded its reach with the launch of Matt’s Community Choir, using music to combat isolation and improve wellbeing.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Impact of Matthews House`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The efforts of Matthews House have had a profound impact on the community, providing vital support to the homeless and vulnerable. Through initiatives like Matt’s Cafe and The Homeless Period Swansea, the charity has been able to address the immediate needs of those in crisis, offering not only sustenance but also dignity and hope. The launch of Matt’s Community Choir has further enriched the lives of individuals, offering a sense of belonging and purpose. The charity’s commitment to making a difference in the lives of those in need has garnered the support and recognition of organisations and individuals alike. To read more about Matthews House, click `,
            },
            {
              link: `https://matthewshouse.org.uk/`,
              text: `here`,
              aria_label: "Navigate to Matthews House's website",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Castell Group’s Partnership with Matthews House`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group’s partnership with Matthews House marks a new chapter in charity collaboration, bringing together two organisations with a shared vision of providing shelter and support to the most vulnerable in society. With a commitment to working closely with Matthews House, Castell Group plans to arrange events, raise funds, volunteer, and donate to further the charity’s cause. The partnership reflects both organisations’ dedication to making a tangible difference in the lives of those facing homelessness and hardship.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our Community Engagement Manager, Chloe, commented “We are thrilled to be partnering with Matthews House in our shared commitment to making a positive impact on homelessness.” Take a look at our `,
            },
            {
              link: `/blog/chloe-community-engagement-manager/`,
              text: `Community Engagement Manager’s initiatives`,
              aria_label: "Navigate to Chloe's blog post",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Road Ahead`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As Matthews House continues to expand its reach and impact in the community, the call for support from like-minded and generous individuals grows stronger. The charity welcomes volunteers, discounted services, and financial contributions to aid in their mission of providing hospitality and hope to those in need. By being part of the next chapter in Matthews House’s journey, individuals and organisations have the opportunity to make a meaningful difference in the lives of the many who rely on the charity's support.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Power of Collaboration`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Collaboration is at the heart of creating lasting and impactful change. By working together, organisations like Matthews House and Castell Group can leverage their resources, expertise, and networks to create a stronger lifeline for those in need. The partnership between the two organizations represents a powerful example of how collective action can amplify the impact of charity initiatives, ultimately transforming lives and communities.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `How You Can Help`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `There are numerous ways for individuals and organisations to contribute to the mission of Matthews House. Whether it’s volunteering time, providing discounted services, or making regular donations, every contribution plays a crucial role in supporting the charity’s efforts. By coming together to support Matthews House, individuals have the power to make a meaningful and lasting impact on the lives of those facing homelessness and hardship.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Conclusion`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The partnership between Castell Group and Matthews House marks the beginning of an inspiring collaboration, dedicated to providing hospitality and hope to the homeless and vulnerable. As the two organisations work together to amplify their impact, they invite others to join them in their mission of creating positive change. By supporting Matthews House and participating in their initiatives, individuals have the opportunity to contribute to a brighter future for those in need.`,
            },
          ],
        },
      ],
    ],
  },
  cowbridge_completion: {
    id: `cowbridge_completion`,
    no: 10,
    active: true,
    title: `Castell Group Announces Completion of Affordable Housing Project`,
    date: `2024-01-08`,
    overview: `Affordable housing for Bridgend Castell Group is proud to announce the successful completion and handover of the Cowbridge Road social housing project in Bridgend. This...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c005-img-1.png`, res_types[1]),
      image_alt: `Cowbridge Photo 1`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c005-img-1.png`, res_types[3]),
            image_alt: `Cowbridge Photo 1`,
          },
        },
        {
          text_block: [
            {
              text: `Affordable housing for Bridgend Castell Group is proud to announce the successful completion and handover of the Cowbridge Road social housing project in Bridgend. This milestone marks the delivery of 10 EPC A affordable housing units developed for housing association Hafod, representing a significant step forward in addressing the need for high-quality, energy-efficient social housing in the community.`,
            },
          ],
        },
        {
          text_block: [
            {
              main: true,
              text: `To read more about Hafod and what they do, click `,
            },
            {
              link: `https://www.hafod.org.uk/`,
              text: `here`,
              aria_label: "Navigate to Hafod's website",
            },
            {
              text: `.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c005-img-2.png`, res_types[3]),
            image_alt: `Cowbridge Photo 2`,
          },
        },
        {
          text_block: [
            {
              text: `The Cowbridge Road project, located at 77 Cowbridge Road, Bridgend, includes the development of 10 affordable housing units. The existing house at no. 77 was converted into two apartments and the rear gardens of numbers 77 and 75 being used to construct a block containing eight additional flats.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Sustainable, environmentally friendly and affordable housing`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The project also features onsite parking, solar panels, EV chargers, electric storage heating and water cylinders for sustainable energy practices. With apartments ranging from 50 to 70 square meters, the project offers comfortable and environmentally friendly living spaces for residents.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Catering to a diverse community`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In terms of accessibility, various features were implemented to enhance ease of use, and accommodate individuals with diverse needs. Several considerations were taken into account in the design, such as rise and fall kitchens, setting control heights for accessibility (including window handles), ensuring level access to front and patio areas, and incorporating wet rooms.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Additionally, the design accommodates wheelchair turning circles and provides wider disabled parking spaces with drop curbs for easier footpath access. This comprehensive approach to accessibility ensures that the development is inclusive and caters to a diverse range of the community.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c005-img-3.png`, res_types[3]),
            image_alt: `Cowbridge Photo 3`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Overcoming hurdles`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `“Despite facing challenges such as issues with asbestos, and the need for some demolition on the existing house, we are proud to have overcome these obstacles to deliver a high-quality, energy-efficient housing solution for the community,” said Dorian Payne, MD at Castell Group.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Committed to providing affordable housing for those truly in need`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `This achievement underscores Castell Group’s commitment to addressing the  housing needs of the community and delivering sustainable, well-designed housing solutions. The company’s dedication to excellence and collaboration with housing associations such as Hafod reflects its ongoing efforts to contribute to the creation of thriving, inclusive communities.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Take a look at our `,
            },
            {
              link: `/projects`,
              text: `affordable housing projects.`,
              aria_label: "Navigate to Castell Group's projects page",
            },
          ],
        },
      ],
    ],
  },
  neath_workingmans_announcement: {
    id: `neath_workingmans_announcement`,
    no: 9,
    active: true,
    title: `Regenerating Neath: Castell Group announces transformative housing project at former Neath Working Men’s Club`,
    date: `2023-12-11`,
    overview: `Meaningful Partnerships As a social, sustainable and affordable property developer, Castell Group, is thrilled to unveil a ground-breaking collaboration with Pobl Group and Neath Port...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c019-cgi-1.jpeg`, res_types[1]),
      image_alt: `Neath Workingmens Club CGI`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c019-cgi-1.jpeg`, res_types[3]),
            image_alt: `Neath Workingmens Club CGI`,
          },
        },
        {
          text_block: [
            {
              text: `Meaningful Partnerships As a social, sustainable and affordable property developer, Castell Group, is thrilled to unveil a ground-breaking collaboration with Pobl Group and Neath Port Talbot County Borough Council. This blog will dive into the initiation of an innovative housing development on the site of the former Neath Working Men’s Club, which will assist in regenerating Neath Town Centre.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `This project, generously supported by the Social Housing Grant funding from the Welsh Government, aims to meet the pressing demand for high-quality, affordable housing in the area while contributing significantly to the ongoing regeneration of the town centre.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Regenerating Neath Town Centre`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The project involves the demolition of the former Neath Working Men’s Club and the construction of 36 new homes, consisting of 32 one-bedroom apartments and 4 two-bedroom apartments. All units will be designated for Social Housing.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Situated on Wind Street in the heart of Neath Town Centre, the development capitalises on the high housing demand in the area, adjacent to the Council’s new multi-million-pound leisure centre and library, and in close proximity to local shops and businesses.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0009-1.jpg`, res_types[3]),
            image_alt: `Neath Workingmens November 2023`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Preserving Heritage, Building Tomorrow`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Having been a cornerstone of community life dating back to at least the 1960s, the beloved ‘Workies’ holds a special place in Neath’s history.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group and Pobl are committed to ensuring that the new development not only addresses the critical need for housing but also contributes to the vibrant revitalisation of Neath town centre as a desirable place to live and work.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Sustainable solutions: Regenerating Neath`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The site’s strategic accessibility by foot, cycle, bus, and train positions it as an ideal location for residential development. The project aligns seamlessly with Castell Group’s commitment to carbon reduction, with all homes designed to achieve an EPC A rating, incorporating sustainable building practices and advanced technologies to meet Welsh Government standards.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Contracts for this transformative development were officially signed on October 6th, with the project slated for completion within 112 weeks.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group and Pobl remain steadfast in their dedication to creating vibrant, sustainable communities and view this housing development as a pivotal step towards addressing the housing needs of the local community while upholding the highest standards of environmental responsibility. We’re delighted to be able to assist in regenerating Neath.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `About Pobl Group`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Pobl are a not-for-profit organisation with half a century of creating affordable homes, Pobl Group operates across Wales, managing more than 18,000 homes, providing care and support to almost 17,000 individuals, and employing over 2,000 people.`,
            },
          ],
        },
        {
          text_block: [
            {
              bold: true,
              text: `Rosy Steel, Project Manager for Pobl Group said: `,
            },
            {
              italic: true,
              text: `“In proud collaboration with Castell Group, the Welsh Government and Neath Council, our new social rented apartments in Neath town centre embody our shared vision for vibrant and sustainable communities through accessible, quality housing. This project represents our commitment to delivering practical solutions, and revitalizing the former Neath Working Men’s Club is not just about building houses, our focus is the ongoing regeneration of Neath town centre.”`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Read more about Pobl Group `,
            },
            {
              link: `https://www.poblgroup.co.uk/`,
              text: `here.`,
              aria_label: "Navigate to Pobl's website",
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `About Castell Group`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group is a leading property development company committed to providing high-quality, energy-efficient, and affordable social and disability-adapted housing for those truly in need. Through strong collaborations with private investors and registered social landlords, the company has established a reputation for excellence in the South Wales region.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `With a team of experienced and passionate professionals, Castell Group has a proven track record of successfully delivering exceptional housing projects. Their commitment to sustainability is evident in their dedication to achieving EPC A ratings for all their properties, minimizing environmental impact and reducing running costs for residents.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group’s land pipeline boasts over 850 units of high-quality homes across South Wales, demonstrating their unwavering commitment to addressing the housing needs of the region. By working exclusively with registered social landlords, they ensure that their efforts directly benefit those who require affordable and accessible housing options.`,
            },
          ],
        },
        {
          text_block: [
            {
              bold: true,
              text: `Dorian Payne, Castell Group’s Managing Director, added: `,
            },
            {
              italic: true,
              text: `“We are looking forward to working with Pobl on this new social housing project in Neath. The former workingman’s club will be transformed by our team into high-quality, affordable, and low-carbon housing for the area. Through our partnerships with clients like Pobl, we can deliver much-needed social housing in South Wales.”`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Check out Castell Group’s Pipeline Projects `,
            },
            {
              link: `/projects`,
              text: `here.`,
              aria_label: "Navigate to Castell Group's projects page",
            },
          ],
        },
      ],
    ],
  },
  castell_argus_awards_2023: {
    id: `castell_argus_awards_2023`,
    no: 8,
    active: true,
    title: `Celebrating Success: Castell’s Double Triumph at South Wales Argus Business Awards`,
    date: `2023-11-24`,
    overview: `A Night of achievements The South Wales Argus Business Awards was an evening filled with anticipation and excitement for the Castell team. The atmosphere was...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0008-1.jpg`, res_types[2]),
      image_alt: `South Wales Argus Business Awards Group Photo`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0008-1.jpg`, res_types[4]),
            image_alt: `South Wales Argus Business Awards Group Photo`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Night of achievements`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The South Wales Argus Business Awards was an evening filled with anticipation and excitement for the Castell team. The atmosphere was electric as we waited to hear the results. When the moment arrived, it felt surreal to hear our name called. We won the prestigious “Start-Up of the Year” award and our Managing Director, Dorian Payne, received a well-deserved award for “Young Entrepreneur of the Year”. This remarkable achievement has left us inspired and eager to continue our mission of providing much-needed social housing.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Read more about the build up to the awards `,
            },
            {
              link: `https://castellgroup.co.uk/south-wales-argus-business-awards/`,
              text: `here`,
              aria_label: "Navigate to Castell's blog post",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Grateful Acknowledgment`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `None of this would have been possible without the dedication and hard work of the entire Castell team. Every member played a crucial role in our journey to success. Dorian Payne expressed his gratitude, acknowledging the team’s efforts as instrumental in this incredible achievement. Dorian said, `,
            },
            {
              italic: true,
              text: `“This couldn’t have happened without the hard work of the entire Castell team! I’d also like to thank the judges and all those who have worked alongside us to contribute to our success so far. This is just the beginning of an exciting journey for Castell!”`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0008-2.jpg`, res_types[4]),
            image_alt: `Dorian Payne celebrating double triumph`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `A Catalyst for Positive Change`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell is not just a property development business; we are advocates of change in the housing sector. The “Start-Up of the Year” award symbolises our rapid growth and determination to make a difference. It also serves as a launchpad for our expansion beyond South Wales, spreading our expertise, values, and passion for social housing to new communities. Our aim is to extend our impact and provide affordable, disable-adapted, and socially inclusive housing accessible to those in need.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Extending our Reach to the West Midlands`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As a fast-growing company, we are thrilled to announce our `,
            },
            {
              link: `https://developmentfinancetoday.co.uk/article-desc-9707_Castell%20Group%20expands%20into%20the%20West%20Midlands`,
              text: `expansion into the West Midlands`,
              aria_label: "Navigate to Development Finance Today's article",
            },
            {
              text: `. We are moving beyond our South Wales roots to bring our mission of social housing to a wider audience. This is not just about geographical reach; it’s about increasing our impact and addressing the need for affordable and accessible housing. The West Midlands is a vibrant region, and we are excited about the possibilities it holds for us to create positive change.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we embark on this exciting journey of expansion and progress, we carry with us a great responsibility. With each new home we build, we aim to make a meaningful impact  on the lives of individuals an d communities. We understand that housing is not just a physical structure; it is a sanctuary where lives are transformed, dreams are nurtured, and futures are shaped. Castell is committed to creating positive change, one home at a time.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Affordable and Inclusive Housing for All`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The need for affordable and accessible housing is more critical now than ever before. Castell is proud to be at the forefront of this change, passionately advocating for social housing. Our commitment is rooted in the belief that everyone deserves a safe and comfortable home. We understand that the absence of a stable living environment impacts individuals and communities profoundly. By providing affordable housing options, we contribute to the overall wellbeing and societal coherence.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Stay Tuned for the Future of Castell`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell’s success at the South Wales Argus Business Awards signifies more than just individual achievements. It symbolises the collective pursuit of excellence, innovation, and social responsibility. We are determined to redefine the landscape of social housing, building not just houses but futures. Our aspiration is to create a society where everyone has the opportunity to thrive, regardless of their background or circumstances.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The triumph at the South Wales Argus Business Awards has not only validated our commitment to excellence but also fuelled our passion for social impact. We extend our sincerest thanks to our exceptional team, the judges, and everyone who has contributed to our success. Castell’s journey is just beginning, and we are eager to create lasting change in the lives of individuals and communities through our dedication to social housing.`,
            },
          ],
        },
      ],
    ],
  },
  llamau_bake_off_2023: {
    id: `llamau_bake_off_2023`,
    no: 7,
    active: true,
    title: `The Great Castell Bake Off in Support of Llamau`,
    date: `2023-11-14`,
    overview: `In a world where compassion and generosity are more important than ever, The Great Castell Bake Off recently brought together colleagues. It was for a...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0007-2.jpg`, res_types[1]),
      image_alt: `Castell Bake Off Cupcakes`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0007-2.jpg`, res_types[3]),
            image_alt: `Castell Bake Off Cupcakes`,
          },
        },
        {
          text_block: [
            {
              text: `In a world where compassion and generosity are more important than ever, The Great Castell Bake Off recently brought together colleagues. It was for a heartwarming cause, fostering a sense of community. The aim of this event was to support Llamau, an organisation dedicated to eradicating homelessness for young people and vulnerable women. This baking event showcased both delicious creations and community spirit. Join us as we explore the highlights of this event and gain deeper insights into the remarkable work of Llamau.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Great Castell Bake Off – A Journey to Sweet Success`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Great Castell Bake Off was a clear success, it was great to see everyone participating! The event gained excitement as our ‘Paul Hollywood,’ Tony Flood, our Site Manager, skilfully ranked our top bakers. The atmosphere was great as competitors showcased their talents, whilst raising awareness and funds for an extraordinary cause.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Winning Delights – Baking Magic at Its Finest`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Tony Flood assumed the crucial role of ranking our top bakers. The competition was fierce, and the results were nothing short of sweet victory.`,
            },
          ],
        },
        {
          bullet_points: [
            {
              header: `First Place:`,
              text: `Our Development and Planning Manager reigned victorious with a heavenly creation – the irresistible Twix Cheesecake.`,
            },
            {
              header: `Second Place:`,
              text: `Our Land and Development Coordinator’s ‘Castell Cupcakes’ secured the spot with a captivating taste, well-deserved.`,
            },
            {
              header: `Third Place:`,
              text: `Our Community Engagement Manager clinched the sweet third position with her delightful lemon drizzle cake.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Take a glimpse into our day with this `,
            },
            {
              link: `https://www.tiktok.com/@castell_group/video/7299496866905885984`,
              text: `short video`,
              aria_label: "Navigate to Castell's TikTok video",
            },
            {
              text: `.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0007-1.jpg`, res_types[3]),
            image_alt: `Castell Bake Off `,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Power of Baking for a Cause: Llamau`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `While the competition was fierce, the true winner of The Great Castell Bake Off was Llamau and the individuals it strives to support. Llamau believes that no young person or vulnerable woman should ever experience homelessness. Founded to offer a safe haven for homeless teenagers, Llamau has since provided support and shelter. Over 67,000 individuals facing homelessness or at-risk situations have been helped.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Llamau: Recognising the Complexity of Homelessness`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `While many may think that the focus of a homelessness charity is to provide accommodation, Llamau understands the issues faced by individuals experiencing homelessness are multifaceted. At Llamau, every person seeking support is treated as an individual, with their unique strengths and needs acknowledged and nurtured. It is through this personalized approach that Llamau provides the highest quality support and empathy.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At Llamau, their approach is guided by a set of core values that ensure unwavering dedication to those they support. By respecting individuals and actively listening to their stories, this charity creates an environment where voices are heard and understood. Through encouragement and fostering a culture of learning, Llamau empowers individuals to rebuild futures with newfound strength and resilience.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Creating Lasting Change`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Homelessness can affect anyone, and Llamau recognises the importance of long-lasting solutions. By providing a holistic support system that addresses not only housing needs but also mental health, education, and employment, Llamau enables individuals. This support helps them break free from the cycle of homelessness. Through unwavering commitment and refusal to give up on those in need, Llamau brings hope and change to countless lives across Wales.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Great Castell Bake Off showcases the strength of community support. A shared passion for a cause brings people together. The event not only raised awareness but also generated vital funds for Llamau’s ongoing efforts. It showcased remarkable talents within our organisation, reinforcing values of compassion, empathy, and the generosity we hold dear.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Sweet Taste of Success`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The bake off left us with satisfied taste buds, but also knowing that we contributed to a truly meaningful cause. Through creativity, passion, and dedication, everyone involved made a lasting difference in the lives of young people and vulnerable woman. The event served as a powerful reminder that together, we can overcome even the most complex challenges.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Continuing the Journey in support of Llamau`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Great Castell Bake Off reminds us that each of us has the power to create positive change in our communities. Whether it’s organising a similar event, volunteering, or donating, every action has the potential to transform lives. By finding creative ways to lend support, we can collectively contribute to a brighter future for those in need.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In a commitment to making a positive impact, Castell Group proudly selected Llamau as our `,
            },
            {
              link: `/blog/our-charity-of-the-year/`,
              text: `Charity of the Year`,
              aria_label: "Navigate to Castell's blog post",
            },
            {
              text: `. As the year draws to a close, we have one remaining month to maximise our fundraising efforts for this worthy cause. With each slice of cake shared, we move closer to a society where homelessness becomes a thing of the past. Every donation contributes to this progress. Let’s continue to support the incredible work of Llamau and stand shoulder to shoulder with those who need our help the most.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Check out more info on the heartwarming work of Llamau `,
            },
            {
              link: `https://www.llamau.org.uk/`,
              text: `here`,
              aria_label: "Navigate to Llamau's website",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
    ],
  },
  south_wales_argus_finalists_2023: {
    id: `south_wales_argus_finalists_2023`,
    no: 6,
    active: true,
    title: `South Wales Argus Business Awards: A Double Finalist Achievement for Castell Group`,
    date: `2023-10-30`,
    overview: `Castell Group’s Remarkable Journey At Castell Group, we’ve embarked on a remarkable journey, and as a result, it’s one that we’re excited to share with...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0006-1.jpg`, res_types[2]),
      image_alt: `South Wales Argus Business Awards`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0006-4.jpg`, res_types[3]),
            image_alt: `South Wales Argus Business Awards`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Castell Group’s Remarkable Journey`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At Castell Group, we’ve embarked on a remarkable journey, and as a result, it’s one that we’re excited to share with you. Notably, our Managing Director, Dorian Payne, has been selected as a finalist in the prestigious “Young Entrepreneur of the Year” category at the South Wales Argus Business Awards. This notable achievement is a testament to the dedication and hard work that have been the driving forces behind Castell Group. Furthermore, Castell Group has been nominated as “Start-Up Business of the Year”.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The pride we feel comes from the awareness that nominations for prestigious awards aren’t the result of mere luck. Rather, they stem from our unwavering aspiration to create a positive impact in the field of social housing. Moreover, the reflection of Dorian’s innovative entrepreneurial spirit truly radiates across Castell Group, inspiring us to continue aspiring for excellence. So, let’s dive deeper into our story, explore what makes Castell Group unique, and understand why both nominations are significant to us.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Celebrating Collective Achievement: South Wales Argus Business Awards`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At the heart of Castell Group is a vision to create something extraordinary. We are unwavering in our dedication to delivering top-notch services, evident in our ‘Start-Up Business of the Year’ nomination.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Moreover, the nomination of Castell Group for this prestigious award not only celebrates our collective achievements but also stands as a testament to Dorian’s wise leadership. It’s even more fulfilling to know that these nominations result from the hard work, team spirit, and passion that each member of Castell Group embodies. Our team’s shared values of honesty, collaboration, quality, and reliability have driven our success, propelling us toward this moment. We will forever uphold these core values as we continue to grow and make a difference in the world of social housing.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Dorian Payne: The Driving Force`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Dorian is a champion of innovation. Under his inspiring leadership, Castell Group has taken bold strides in navigating our journey through uncharted territories. Challenges have seamlessly transformed into opportunities, and ideas have effectively transitioned into reality. Consequently, his nomination as ‘Young Entrepreneur of the Year’ stands not only as a personal honour but also as a testament to the hard work and dedication of the entire team.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `These nominations exemplify our MD’s entrepreneurial journey, which began at 16, studying accountancy in college, showcasing dedication and innovation. His innovation, resilience, and sheer grit continue to drive our operations, shaped the organization to where it is today.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0006-2.jpg`, res_types[3]),
            image_alt: `Dorian Payne at the Developers Club`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Leadership and Inspiration`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Under his visionary leadership, Castell Group has grown from strength to strength, proving that every leap taken was nothing less than a leap of faith. Dorian’s focus on building strong relationships with partners, clients, and, most importantly, the Castell team, defines our path to success.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Community Engagement`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Dorian believes in giving back to the community. Guided by his forward-thinking approach, Castell Group has been actively involved in various community initiatives, diligently supporting local causes and making a positive impact beyond the business world. Consequently, the pattern of entrepreneurship well-suited to the 21st century epitomizes his nomination as a finalist in the category of Young Entrepreneur of the Year at this year’s South Wales Argus Business Awards. His young age, unwavering dedication, indomitable spirit, and innovative mindset make Dorian’s approach a guiding example for aspiring entrepreneurs.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Read more about Dorian’s successful career `,
            },
            {
              link: `https://www.business-live.co.uk/commercial-property/welsh-social-housing-entrepreneur-who-25261607`,
              text: `here`,
              aria_label: "Navigate to Business Live's article",
            },
            {
              text: `.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Testament of Efficient Strategies`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Under the guidance of Dorian, Castell Group has dared to dream big and to follow through with efficient strategies and bold initiatives. We have reshaped notions of what a start-up company can achieve in a short time. This journey speaks volumes about our unwavering ambition and optimism.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our success so far, as evidenced by this nomination, is a heartening affirmation of our strategy’s effectiveness, and we will continue to abide by our growth mission.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Importance of Social Housing`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In addition to our commitment to affordable, disability-adapted, EPC A-rated homes, Castell Group recognizes that constructing social housing is not only a moral imperative but also of vital importance for positive change within society. We firmly believe that safe and affordable housing is not just a basic need but a cornerstone of stable, thriving communities. `,
            },
            {
              link: `/blog/more_social_housing/`,
              text: `Social housing`,
              aria_label: "Navigate to Castell's blog post",
            },
            {
              text: ` plays a pivotal role in enhancing the well-being and quality of life for individuals and the community at large.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Affordable housing significantly contributes to economic and social well-being. When individuals and families have access to stable housing, they can concentrate on personal and professional growth. Consequently, this leads to a more productive and empowered workforce, effectively driving local economic development.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Reducing Homelessness`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `One of the critical roles of social housing is in reducing homelessness. By offering safe, affordable housing, we assist vulnerable populations, including the homeless, in rebuilding their lives and regaining stability.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Social housing often serves as a lifeline for vulnerable populations, including the elderly, individuals with disabilities, and low-income families. It offers a safety net, ensuring that those who are most in need have access to decent housing that suits their specific requirements.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As Castell Group continues to grow, we remain steadfast in our commitment to making a positive impact in the social housing sector. Furthermore, we understand that the challenges are multifaceted, but we’re driven by the belief that everyone deserves a safe and affordable place to live. We actively seek collaborative partnerships with housing associations, local authorities, and organizations dedicated to improving housing access and affordability. By working together, we can amplify our efforts to address the growing demand for social housing solutions.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Building the Future on Solid Foundations`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our accomplishment and recognition were only possible due to the concerted efforts of dedicated Castell Group team members. Our future success depends on the solid foundation we’ve laid, speaking volumes about our shared efforts and commitment.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Dorian’s determination provides us with favourable winds as we sail forward in this journey of exploration. With a determined mindset and core values as our guiding light, we cultivate a culture of continuous growth, progress, and innovation`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Forward, Onwards and Upward`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `With these nominations, we’re fueled to march forward, scale greater heights, and delve into unexplored territories. Here’s to Dorian Payne, Castell Group, and our lasting legacy of excellence, innovation, and expansion.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our journey is filled with milestones and achievements, made possible by the trust and support of our clients and partners. Your unwavering faith in us is the cornerstone of our accomplishments, and for that, we’re deeply grateful.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Celebrating Excellence with South Wales Argus Business Awards Finalists`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As the South Wales Argus Business Awards approach, we eagerly anticipate the results. We’re excited about our recognition and celebrating excellence in the broader business community in South Wales. These awards spotlight the dynamic and innovative business landscape in our region. We’re honoured to be part of it.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our journey embodies embracing change and envisioning growth, epitomized profoundly by our MD. It’s a proud moment for Castell Group. We’re immensely grateful for this honour and eager for the opportunities and challenges ahead. Onwards and upwards!`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Stay Connected with Castell Group!`,
            },
          ],
        },
        {
          text_block: [
            {
              link: `https://share-eu1.hsforms.com/10Thr_zz3RzCIP9dtyPL9xgfynva`,
              text: `Stay tuned`,
              aria_label: "Navigate to Castell's newsletter subscription form",
            },
            {
              text: ` for updates as we bring you the latest news and developments from Castell Group. We appreciate your continued support, and we can’t wait to share the next chapters of our journey with you. It’s going to be an exciting and transformative journey ahead!`,
            },
          ],
        },
      ],
    ],
  },
  greener_future_with_ecologi: {
    id: `greener_future_with_ecologi`,
    no: 5,
    active: true,
    title: `Building a Greener Future with Ecologi`,
    date: `2023-10-20`,
    overview: `Building a better future starts with taking action today, and as a construction company, we believe it is our responsibility to contribute to the global...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0005-1.jpeg`, res_types[2]),
      image_alt: `Building a Greener Future with Ecologi`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0005-1.jpeg`, res_types[3]),
            image_alt: `Building a Greener Future with Ecologi`,
          },
        },
        {
          text_block: [
            {
              text: `Building a better future starts with taking action today, and as a construction company, we believe it is our responsibility to contribute to the global effort of combating climate change. That’s why we’re proud to announce our partnership with Ecologi, an environmental organization dedicated to reforestation projects around the world. In this blog post, we will explain how we are supporting Ecologi’s mission by planting 100 trees in the UK for every new construction project we undertake. Join us as we embark on an inspiring journey towards a greener planet, and more sustainable future. Check out the impact `,
            },
            {
              link: `https://ecologi.com/`,
              text: `Ecologi`,
              aria_label: "Navigate to Ecologi's website",
            },
            {
              text: `have made.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Building Green, Building Forward`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As a construction company, we understand that our industry has a significant impact on climate change due to emissions, waste, and resource consumption. Therefore, we have made a conscious decision to adopt sustainable practices and prioritize climate-positive actions in our work. While many companies simply focus on reducing their negative impact, we believe in going a step further by actively contributing to environmental restoration and preservation.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Partnering with Ecologi for a Greener Planet`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Ecologi’s mission aligns perfectly with our vision for a greener planet. Through their reforestation projects, they aim to restore and protect natural habitats, offset carbon emissions, and empower communities. By partnering with Ecologi, we have committed to planting 100 trees in the UK for every construction project we undertake. This ensures that our work not only benefits communities but also contributes to the fight against climate change.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Power of Reforestation`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Reforestation is a crucial aspect of combating climate change. Trees play a pivotal role in regulating our climate by absorbing carbon dioxide, a greenhouse gas contributing to global warming. Through the process of photosynthesis, trees convert carbon dioxide into oxygen, revitalizing the atmosphere and mitigating the impact of emissions. Additionally, forests serve as natural carbon sinks, storing vast amounts of carbon that would otherwise be released into the atmosphere.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Restoring UK’s Natural Heritage`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our commitment to planting trees through Ecologi’s reforestation projects extends to the magnificent landscapes of the United Kingdom. The UK has a rich history of diverse ecosystems, from ancient woodlands to expansive moors. Sadly, many of these habitats have been lost due to deforestation and urbanization. By planting trees in the UK, we contribute to the rejuvenation of these natural habitats, providing a much-needed refuge for wildlife and functioning as crucial carbon capture reservoirs.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Benefits of Tree Plantation`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The impact of our tree plantation initiatives reaches far beyond carbon sequestration. Trees provide numerous environmental, social, and economic benefits. From improving air quality to reducing soil erosion, from providing shelter for wildlife to creating recreational spaces for communities, trees are invaluable assets that bring harmony back to our planet. By planting trees, we are investing in a more sustainable and resilient future for generations to come.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Inspiring Environmental Stewardship`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Our commitment to climate-positive construction projects does not end with tree planting. We aim to inspire and educate our staff, clients, and partners about the importance of environmental stewardship. By showcasing the positive impact of our initiatives, we hope to encourage others in the construction industry to join us on this transformative journey towards sustainability. Together, we can make a difference and build a greener future.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Fostering Sustainable Partnerships`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As a construction company committed to climate action, we recognize the power of collaboration. We actively seek out sustainable suppliers, contractors, and stakeholders who share our vision. By fostering partnerships with like-minded organizations, we can amplify our impact. Not only does this promote best practices, but it drives positive change within our industry. Together, we can build a greener planet, one project at a time.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Global Impact of Local Actions`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `By focusing on reforestation projects in local communities, we have the opportunity to create a visible impact on the environment. However, it is vital to recognize that our actions also contribute to the global fight against climate change. As more companies adopt climate-positive practices, the cumulative effort will help offset carbon emissions, restore natural habitats, and protect the planet for future generations.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Join Us on the Journey to a Greener Planet`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We firmly believe that climate action is a collective responsibility that requires everyone’s participation. We invite our clients, employees, partners, and the wider community to join us in supporting Ecologi’s mission. Together, we can make a significant impact on restoring the planet’s natural balance, one tree at a time. Let us take this opportunity to not only build structures but also construct a sustainable future for generations to come.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Incorporating climate-positive actions into our projects and partnering with Ecologi’s reforestation initiative is just the beginning of our commitment to a greener future. We are determined to continuously innovate and implement sustainable practices. By doing so, we play a crucial role in preserving the planet.`,
            },
          ],
        },
      ],
    ],
  },
  fast_growth_50_2023: {
    id: `fast_growth_50_2023`,
    no: 4,
    active: true,
    title: `Castell Group Qualifies for the 2023 Wales Fast Growth 50 List!`,
    date: `2023-10-05`,
    overview: `Castell Group on the Wales Fast Growth 50 List In the fast-paced world of business, it is important to stop and celebrate our achievements along...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0004-1.jpg`, res_types[2]),
      image_alt: `Dorian Payne receiving a Fast Growth 50 Award`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0004-1.jpg`, res_types[4]),
            image_alt: `Dorian Payne receiving a Fast Growth 50 Award`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Castell Group on the Wales Fast Growth 50 List`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In the fast-paced world of business, it is important to stop and celebrate our achievements along the way. We are thrilled to announce that we have qualified for the prestigious Fast Growth 50 list in Wales, an index that acknowledges our remarkable achievements as one of the fastest-growing firms in the region. With over 218,000 businesses in Wales, this recognition is an incredibly proud moment for our entire team. In addition to this honour, we are also thrilled to share that we have been shortlisted for the title of the fastest-growing Construction and Building Services firm in Wales at the Fast Growth 50 Awards This milestone is a testament to our team’s dedication, innovation, and unwavering pursuit of excellence.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `As we bask in the glory of this achievement, we want to take a moment to reflect on our success so far and the tremendous potential that lies ahead.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Pioneering Growth Amidst Stiff Competition`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group’s inclusion in the 2023 Wales `,
            },
            {
              link: `https://fastgrowth50.com/`,
              text: `Fast Growth 50`,
              aria_label: "Navigate to Fast Growth 50's website",
            },
            {
              text: ` list illustrates our exceptional ability to thrive in a highly competitive market. Despite numerous challenges faced by businesses in Wales, our commitment to constant improvement and quality-driven solutions has enabled us to transcend industry norms and achieve unprecedented growth.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Recognizing Wales’ Leading Businesses`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Fast Growth 50 Index, a meticulously researched league table, serves as a platform to honour remarkable companies across the United Kingdom. Over the last 24 years, it has identified and celebrated businesses that exemplify exceptional growth and excellence in their respective regions and nations. As proud members of this exclusive club, Castell Group is steadfast in its commitment to representing the ever-evolving business landscape of Wales.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group’s success story in the 2023 Fast Growth 50 Awards further underscores our remarkable achievements. Shortlisted as the fastest-growing Construction and Building Services firm in Wales, this accolade showcases our unwavering commitment to delivering innovative and sustainable solutions within the industry. This recognition is a testament to the dedication of our talented team, whose collaborative efforts continually push the boundaries of excellence.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Unveiling the Methodology Behind FastGrowth50 Wales`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Fast Growth 50 Index employs an intricate and comprehensive methodology to identify the fastest-growing companies in Wales. The rigorous evaluation process includes meticulous analysis of financial growth indicators, verified by independent accounting experts. By adhering to stringent selection criteria, the Index ensures that only the most deserving companies are recognized and showcased, making this accolade all the more significant for Castell Group.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Charting Castell Group’s Ascendancy: A Tale of Determination`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell Group’s remarkable growth journey is a product of vision, resilience, and an unwavering spirit to overcome obstacles. Since its inception, our company has demonstrated a steadfast commitment to delivering high-quality solutions, forging strong partnerships, and nurturing a culture of innovation. The Fast Growth 50 recognition serves as validation for the countless hours of hard work, coupled with unwavering determination, invested by every member of the Castell Group family.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `The Power of Teamwork in Achieving Exponential Growth`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At Castell Group, we firmly believe that our success is a direct result of the `,
            },
            {
              link: `/about-us/`,
              text: `exceptional individuals`,
              aria_label: "Navigate to Castell's About Us page",
            },
            {
              text: ` who form the backbone of our organization. United by a shared vision and fuelled by a collective passion for excellence, our team has consistently pushed the boundaries of innovation and exceeded expectations. The Fast Growth 50 recognition would not have been possible without the unwavering commitment, resilience, and ingenuity of our incredible workforce.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Forging Strong Partnerships: A Catalyst for Success`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The importance of forging strategic partnerships and nurturing fruitful collaborations cannot be overstated. Our commitment to building strong relationships with clients, suppliers, and industry stakeholders has been pivotal to our growth. These partnerships have facilitated knowledge exchange, provided valuable insights, and fuelled mutually beneficial growth. Through collaboration, we have developed a cohesive ecosystem that fosters trust, innovation, and long-term success.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Realising the Vision: Celebrating Castell Group’s Future Prospects`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In light of our recognition by the Fast Growth 50 Index, Castell Group is more motivated than ever to continue its upward trajectory. Armed with an unrelenting drive to exceed expectations, we eagerly await confirmation of our ranking on the league table. As we strive for excellence, guided by our core values, we are committed to embracing emerging trends and technologies, forging new partnerships, and leaving an indelible mark of innovation and success.`,
            },
          ],
        },
      ],
    ],
  },
  castell_appointed_to_whf: {
    id: `castell_appointed_to_whf`,
    no: 3,
    active: true,
    title: `Castell Appointed to Welsh Housing Framework`,
    date: `2023-08-18`,
    overview: `Procurement has never been easier You can now find Castell Group on the list of appointed companies on the WPA (Welsh Procurement Alliance) Housing Construction...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0003-1.jpg`, res_types[2]),
      image_alt: `Welsh Procurement Alliance`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0003-1.jpg`, res_types[2]),
            image_alt: `Welsh Procurement Alliance`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Procurement has never been easier`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `You can now find Castell Group on the list of appointed companies on the WPA (Welsh Procurement Alliance) Housing Construction South and Mid Wales DPS (Dynamic Purchasing System).`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The DPS provides buyers with access to a pool of pre-qualified contractors for housing construction projects.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `It is an open market solution, developed following consultation with `,
            },
            {
              link: `https://www.gov.wales/`,
              text: `Welsh Government`,
              aria_label: "Navigate to Welsh Government's website",
            },
            {
              text: ` and the Councils and Housing Associations across South and Mid Wales.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Find pre-qualified suppliers for housing construction`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Castell joins a select group of construction businesses in Wales on this `,
            },
            {
              link: `https://www.welshprocurement.cymru/dps/housing-construction-south-and-mid-wales-hcswa/`,
              text: `major framework`,
              aria_label: "Navigate to Welsh Procurement Alliance's website",
            },
            {
              text: `.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Frameworks offer buyers an assured service due to the qualification process.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `To be included in this framework, which includes industry heavyweights like Willmott Dixon Construction Ltd. and Lovell Partnership Ltd, is an honour.`,
            },
          ],
        },
        {
          text_block: [
            {
              link: `https://www.linkedin.com/in/dorianpayneproperty/`,
              text: `Dorian Payne, MD: `,
              aria_label: "Navigate to Dorian Payne's LinkedIn profile",
            },
            {
              italic: true,
              text: `“We are excited to take this step with the WPA and welcome the opportunities it will bring. We join a group of renowned construction businesses in Wales ready to deliver much needed homes,” commented Castell Group MD Dorian Payne.`,
            },
          ],
        },
        {
          text_block: [
            {
              italic: true,
              text: `“Castell is predominately a land-led social and affordable housing development company with 200 x homes currently under construction and 700 x homes in our land pipeline, however by joining the DPS we can further advance our collaboration with Registered Social Landlords and help to reduce the waiting lists for social housing!”`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Benefits of using a DPS`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `By using a construction framework, project owners can be confident of the highest standards for their project.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Overall, frameworks such as this reduce risk, and improve transparency.`,
            },
          ],
        },
        {
          bullet_points: [
            {
              header: "Time saving",
              text: `Quick and easy access to approved suppliers.`,
            },
            {
              header: "Compliance",
              text: `Ensures procurement is compliant with U.K. public sector procurement rules.`,
            },
            {
              header: "Quality and value",
              text: `Only suppliers who offer quality and value are pre-qualified with the right qualifications, experience and financial standing to deliver your project.`,
            },
            {
              header: "Choice",
              text: `Access to a wide number of pre-qualified suppliers.`,
            },
            {
              header: "Local delivery",
              text: `Offers the opportunity to engage with local suppliers supporting the wider Government aim of working with local businesses for economic growth.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Also, by working with a construction framework, contractors can help to make the construction industry more sustainable and to contribute to the circular economy.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We couldn’t be more proud of our team for getting us to this point and creating further opportunities where we can provide high-quality housing for those who need it most.`,
            },
          ],
        },
      ],
    ],
  },
  samlet_shortlisted: {
    id: `samlet_shortlisted`,
    no: 2,
    active: true,
    title: `Samlet Road Project Shortlisted for Inside Housing Development Awards`,
    date: `2023-07-28`,
    overview: `Swansea project reaches industry award finals We are delighted to announce that our Samlet Road project, in partnership with Caredig, has been shortlisted for an...`,
    image: {
      image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c010-cgi-4.jpg`, res_types[2]),
      image_alt: `Samlet Road CGI`,
    },
    sections: [
      [
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}c010-cgi-4.jpg`, res_types[3]),
            image_alt: `Samlet Road CGI`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Swansea project reaches industry award finals`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We are delighted to announce that our Samlet Road project, in partnership with `,
            },
            {
              link: `https://www.caredig.org.uk/`,
              text: `Caredig`,
              aria_label: "Navigate to Caredig's website",
            },
            {
              text: `, has been shortlisted for an award at the `,
            },
            {
              link: `https://ihda.co.uk/`,
              text: `2023 Inside Housing Development Awards`,
              aria_label: "Navigate to Inside Housing Development Awards' website",
            },
            {
              text: `.`,
            },
          ],
        },
        {
          text_block: [
            {
              link: `/projects/C010`,
              text: `Samlet Road`,
              aria_label: "Navigate to Castell's Samlet Road project page",
            },
            {
              text: ` is a 35-home scheme in Llansamlet, Swansea. The development comprises a mix of one and two bedroom apartments and two bedroom houses. The homes are available to rent through Caredig, providers of housing solutions for people who need support to maintain their tenancy. Inside Housing recognised the scheme for the ‘Best affordable housing development under £10m’ category.`,
            },
          ],
        },
        {
          image: {
            image_url: insertTextbeforeUrlExtention(`${image_url_prefix}9-0002-1.png`, res_types[4]),
            image_alt: `Samlet Road Shortlisted for Inside Housing Development Awards`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `35 high quality homes`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `We are extremely proud of this project, and to be shortlisted is testament to the hard work and dedication of our team, and to the partnership between Castell Group and Caredig. Development and design of each of the homes is to a high standard and specification, with sustainable features to reduce energy costs for tenants.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Samlet Road project (final name still to be selected) is Castell’s third partnership with Registered Social Landlord Caredig. The site is unrecognisable from the empty car dealership originally at the location. Once complete, the homes will be allocated to those most in need of affordable, modern and secure housing.`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Industry recognition`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `The Inside Housing Development Awards are the most prestigious awards in the UK for the residential development sector. The awards recognise the very best in design, construction, and management of residential developments.`,
            },
          ],
        },

        {
          text_block: [
            {
              text: `On November 17, 2023, an event in London will unveil the winners of the prizes, and we look forward to finding out if we were successful. We would like to thank our partners Caredig, and everyone working on the project. We are very grateful for your hard work and dedication. We would also like to thank the Inside Housing team for their recognition of our project.`,
            },
          ],
        },
      ],
    ],
  },
  charity_of_the_year_2023: {
    id: `charity_of_the_year_2023`,
    no: 1,
    active: false,
    title: `Our Charity of the Year`,
    date: `2023-05-16`,
    overview: `Why Llamau is our charity of the year – a look at their inspiring mission Llamau are a truly inspirational charity, and we are privileged...`,
    image: {
      image_url: template_image_dark,
      image_alt: `Why Llamau is our charity of the year – a look at their inspiring mission`,
    },
    sections: [
      [
        {
          headings: [
            {
              main: true,
              text: `Why Llamau is our charity of the year – a look at their inspiring mission`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `Llamau are a truly inspirational charity, and we are privileged to partner with them for a second year. As a provider of social housing through our collaboration with registered social landlords, we understand the importance of having somewhere to call home. It is a vital step in reaching independence and gaining confidence. Especially for young people and women with young children – Llamau’s primary focus groups. Hopefully through this partnership we can raise awareness and funds for this important cause.`,
            },
          ],
        },
        {
          image: {
            image_url: template_image_dark,
            image_alt: `Young mum with small child kneeling on the floor in a living room`,
          },
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `Eradicating homelessness`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `In their mission to eradicate homelessness, `,
            },
            {
              link: `https://www.llamau.org.uk/`,
              text: `Llamau`,
              aria_label: "Navigate to Llamau's website",
            },
            {
              text: ` focus on these key areas:`,
            },
          ],
        },
        {
          bullet_points: [
            {
              header: `Prevention`,
              text: `‘We work to prevent homelessness before it happens and not waiting until people reach crisis point before they get the support they need.’`,
            },
            {
              header: `Moving on`,
              text: `‘We support people through their traumas and into sustainable education, training and employment, which allows them to move on from homelessness.’`,
            },
            {
              header: `A safe home`,
              text: `‘We provide homely and safe supported accommodation and refuges when people who become homeless need a platform to rebuild their lives.’`,
            },
            {
              header: `The Cardiff based charity helped over 4,500 people last year, people like Deanndra (read her story here.) “I’ve got the next part of my journey sorted.”`,
            },
          ],
        },
      ],
      [
        {
          headings: [
            {
              main: true,
              text: `How we’ll be fundraising`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `At Castell Group, we’ll be doing everything we can to reach our fundraising target for Llamau. The incredible work they do is close to our hearts. We know that everyone who comes together for our fundraising activities will have a great time knowing that they’ll be helping people in need.`,
            },
          ],
        },
        {
          text_block: [
            {
              text: `There will be a number of fundraising events throughout the year. Individual feats as well as group activities will help us reach our goal of £5,000 for the year. Keep your eyes peeled for details on these. We hope that those who attend our events will not only enjoy them, but will leave with a little more understanding of just how important the work done by Llamau is. We are proud that Castell Group can play a part in helping people who have been homeless find homes, rebuild their lives and move on.`,
            },
          ],
        },
      ],
    ],
  },
};

export { blog_data };
