import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../pages/Layout.jsx";
import Status404 from "../status_codes_pages/Status404.jsx";
import Home from "../pages/Home.jsx";
import AboutUs from "../pages/AboutUs.jsx";
import Vacancies from "../pages/Vacancies.jsx";
import Vacancy from "../pages/Vacancy.jsx";
import SocialLandlords from "../pages/SocialLandlords.jsx";
import Project from "../pages/Project.jsx";
import Projects from "../pages/Projects.jsx";
import Blog from "../pages/Blog.jsx";
import Blogs from "../pages/Blogs.jsx";
import Landowner from "../pages/Landowner.jsx";
import ContactUs from "../pages/ContactUs.jsx";
// import FreeResources from "../pages/FreeResources.jsx";
import Newsletter from "../pages/Newsletter.jsx";
import { useEffect } from "react";
import { page_links } from "../utils/config/page-links.js";
import ProjectInformationLayout from "../pages/information/ProjectInformationLayout.jsx";
import ProjectInformation from "../pages/information/ProjectInformation.jsx";
import ProjectW3W from "../pages/ProjectW3W.jsx";

// Define the MainRouter component
export default function MainRouter() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      {/* Routes when user is authenticated */}
      <Routes>
        {/* Home Routes */}
        <Route path={page_links["home"].link} element={<Layout />}>
          <Route path={page_links["home"].link} element={<Home />} />
          <Route path={page_links["about_us"].link} element={<AboutUs />} />
          <Route path={page_links["vacancy"].link} element={<Vacancies />} />
          <Route path={`${page_links["vacancy"].link}/:id`} element={<Vacancy />} />
          <Route path={page_links["social_landlord"].link} element={<SocialLandlords />} />
          <Route path={page_links["contact_us"].link} element={<ContactUs />} />
          <Route path={page_links["project"].link} element={<Projects />} />
          <Route path={`${page_links["project"].link}/:id`} element={<Project />} />
          <Route path={page_links["blog"].link} element={<Blogs />} />
          <Route path={`${page_links["blog"].link}/:id`} element={<Blog />} />
          <Route path={page_links["landowner"].link} element={<Landowner />} />
          {/* <Route path={page_links["free_resource"].link} element={<FreeResources />} /> */}
          <Route path={page_links["newsletter"].link} element={<Newsletter />} />
          {/* PUT :ID FOR EACH SITE */}
        </Route>

        <Route path={"/information"} element={<ProjectInformationLayout />}>
          <Route path={"/information/:id"} element={<ProjectInformation />} />
        </Route>

        <Route path="/w3w" element={<ProjectW3W />} />

        {/* Catch-all route for 404 */}
        <Route path="*" element={<Layout />}>
          <Route path="*" element={<Status404 />} />
        </Route>
      </Routes>
    </div>
  );
}
